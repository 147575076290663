import styled from "styled-components";

export const ParentContiner = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
margin-top: 80px;
@media screen and (max-width: 900px){
    width: 100%;
    padding: 16px;
  }
`

export const ChildContiner = styled.div`
    min-width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  
  border-radius: 16px;
  padding: 24px;
  background-color: white;

  border-bottom: 1.5px solid #F6F6F6;
box-shadow: 0px 2px 4px rgba(145, 145, 145, 0.25);
/* filter: drop-shadow(0px 2px 14px rgba(163, 163, 163, 0.25)); */
 margin-left: auto;
 margin-right: auto;

@media screen and (max-width: 900px){
    width: 100%;
    padding: 16px;
  }
`

export const TitleText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.172308px;

  color: #000000;
  padding-bottom: 20px;
`;
