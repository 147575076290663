import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import { Header, BodyText, KitchenName } from "./side-drawer.styles";
import Footer from "../footer/footer.component";
import CloseIcon from "@mui/icons-material/Close";
import { ListItemButton } from "@mui/material";
import { primaryColor } from "../../constants";

export const SideDrawer = ({ kitchen }) => {
  const [clicked, setClicked] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setClicked(open);
    };

  const list = () => (
    <React.Fragment>
      <Box
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          padding: "12px",
        }}
      >
        <Box>
          <ListItemButton onClick={toggleDrawer(false)}>
            <CloseIcon fontSize="large" sx={{ color: primaryColor }} />
          </ListItemButton>
        </Box>
      </Box>
      <Box
        sx={{ padding: "26px", paddingTop: "0px" }}
        role="presentation"
        // onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box>
          <Box sx={{ paddingBottom: "24px" }}>
            <KitchenName>{kitchen?.kitchenName}</KitchenName>
          </Box>
          <Box sx={{ paddingBottom: "24px" }}>
            <Header>Location</Header>
          </Box>
          <Box sx={{ paddingBottom: "24px" }}>
            <BodyText>{kitchen?.fullAddress}</BodyText>
          </Box>
        </Box>
        <Divider />
        {kitchen?.phoneNumber && (
          <Box sx={{ paddingTop: "24px" }}>
            <Box sx={{ paddingBottom: "24px" }}>
              <Header>Mobile</Header>
            </Box>
            <Box sx={{ paddingBottom: "24px" }}>
              <BodyText>
                <a href={`tel:${kitchen?.phoneNumber}`}>
                  {" "}
                  {kitchen?.phoneNumber}{" "}
                </a>
              </BodyText>
            </Box>
          </Box>
        )}
        <Divider />
        {kitchen?.email && (
          <Box sx={{ paddingTop: "24px" }}>
            <Box sx={{ paddingBottom: "24px" }}>
              <Header>Email</Header>
            </Box>
            <Box sx={{ paddingBottom: "24px" }}>
              <BodyText>{kitchen?.email}</BodyText>
            </Box>
          </Box>
        )}
        <Box sx={{ paddingBottom: "24px", paddingTop: "24px" }}>
          <Header>Opening Hours</Header>
        </Box>
        <Box sx={{ paddingBottom: "24px" }}>
          {kitchen?.hours?.schedule.map((obj: unknown) => {
            return (
              <Box
                sx={{
                  justifyContent: "space-between",
                  alignContent: "space-between",
                  display: "flex",
                  paddingTop: "24px",
                }}
              >
                <Box>
                  <BodyText>{Object.keys(obj)}</BodyText>
                </Box>
                <Box>
                  {Object.values(obj)[0].from !== "" ? (
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    
                    <Box sx={{justifyContent: "flex-end", display: "flex"}}>
                      <BodyText>{Object.values(obj)[0].from}</BodyText>
                      <BodyText>-</BodyText>
                      <BodyText>{Object.values(obj)[0].to}</BodyText>
                      </Box>

                      {(Object.values(obj)[0].from2 !== "" && Object.values(obj)[0].from2 !== undefined) && (
                        <Box>
                          <BodyText>{Object.values(obj)[0].from2}</BodyText>
                          <BodyText>-</BodyText>
                          <BodyText>{Object.values(obj)[0].to2}</BodyText>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <BodyText>Closed</BodyText>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Footer hideName={true} />
      </Box>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon fontSize="large" sx={{ color: primaryColor }} />
      </Button>

      <Drawer anchor="right" open={clicked} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </React.Fragment>
  );
};
