export const kitchenClosedOverride = "Not Accepting Orders";
export const kitchenClosedAutoSet = "Auto";

export const primaryColor = "#8600FF";
export const primaryColorDark = "#6C01CC";
export const secondaryColor = "#E36FE4";
export const baseDark600 = "#353435";
export const baseDark500 = "#3F3F3F";
export const baseLight = "#FFFFFF";

export const dishArchived = "Archived"
export const dishSoldout = "Sold Out"
export const dishAvailable = "Available"