import { initializeApp } from "firebase/app";

import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
  NextOrObserver,
} from "firebase/auth";

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  where,
  QueryDocumentSnapshot,
  getDocs,
  updateDoc,
  increment,
  addDoc,
} from "firebase/firestore";

import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

import { getStorage } from "firebase/storage";

import { delay, digitsCount } from "../utils.common";
import { firebaseConfig } from "./configKeys";
import { getAnalytics } from "firebase/analytics";
import { Order } from "../../store/order/order.types";

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const functions = getFunctions(firebaseApp, "australia-southeast1");

if (process.env.REACT_APP_ENV === "development") {
  connectFunctionsEmulator(functions, "127.0.0.1", 6001);
}

export const storage = getStorage(firebaseApp);

// connectFunctionsEmulator(functions, "localhost", 5001);

export const auth = getAuth();

export const analytics = getAnalytics(firebaseApp);

export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);

export const signInWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);

export const db = getFirestore();

export type ObjectToAdd = {
  title: string;
};

// Exports shop-data into firestore
export const addCollectionAndDocuments = async <T extends ObjectToAdd>(
  collectionKey: string,
  objectsToAdd: T[]
): Promise<void> => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);

  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object.title.toLowerCase());
    batch.set(docRef, object);
  });
  await batch.commit();
};

export const getOrderDetails = async (sessionId: string, retries: number) => {
  // const collectionRef = collection(db, "kitchens");
  try {
    const docRef = doc(db, "checkoutSessions", sessionId);

    const docSnap = await getDoc(docRef);

    // retry request if sessionID is not found. Function cold start needs this
    if (docSnap.data() === undefined) {
      if (retries > 0) {
        console.log("Waiting...");
        await delay(5000);
        console.log("Retrying");
        return getOrderDetails(sessionId, retries - 1);
      }
      return;
    }

    const orderId = docSnap.data().orderId;

    const orderRef = doc(db, "orders", orderId);

    const orderSnap = await getDoc(orderRef);
    let orderDetails = orderSnap.data();

    orderDetails["id"] = orderSnap.id;

    return orderDetails;
  } catch (error) {
    return error;
  }
};

// new checkout flow
export const getOrderDetailsNew = async (
  paymentIntentId: string,
  retries: number
) => {
  // const collectionRef = collection(db, "kitchens");
  try {
    const orderQuery = query(
      collection(db, "orders"),
      where("paymentIntent", "==", paymentIntentId),
      where("paymentStatus", "==", "paid")
    );

    const querySnapshot = await getDocs(orderQuery);

    // retry request if sessionID is not found. Function cold start needs this
    if (querySnapshot.empty) {
      if (retries > 0) {
        console.log("Waiting...");
        await delay(5000);
        console.log("Retrying");
        return getOrderDetailsNew(paymentIntentId, retries - 1);
      }
      return;
    }

    let order;
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      order = doc.data();
      order["orderId"] = doc.id;
      return;
    });

    return order;
  } catch (error) {
    return error;
  }
};

export type AdditionalInformation = {
  displayName?: string;
};

export type UserData = {
  createdAt: Date;
  displayName: string;
  email: string;
};

export const createUserDocumentFromAuth = async (
  userAuth: User,
  additionalInformation = {} as AdditionalInformation
): Promise<void | QueryDocumentSnapshot<UserData>> => {
  if (!userAuth) return;
  const userDocRef = doc(db, "users", userAuth.uid);

  const userSnapshot = await getDoc(userDocRef);
  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        ...additionalInformation,
      });
    } catch (error) {
      console.log("error creating the user", error);
    }
  }

  return userSnapshot as QueryDocumentSnapshot<UserData>;
};

export const createAuthUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const createOnlineOrder = async (order: Order) => {
  try {
     // Reference to the document you want to create or overwrite
    const collectionRef = collection(db, 'orders');

    // Write the document
    const orderDoc= await addDoc(collectionRef, order);

    // console.log("Document written with ID: ", orderDoc.id);
    return orderDoc.id
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
};


export const updateOnlineOrder = async (orderId: string, order: Order) => {
  let orderCount = 0;
  let orderIdInitials = "";
  let fullOrderCount = "0000";
  try {
    // Reference to the document where you store the total order count
    const kichenDoc = doc(db, "kitchens", order?.kitchenId);

    // Atomically increment the total order number
    await updateDoc(kichenDoc, {
      orderCount: increment(1),
    });

    // Fetch the updated document to get the new order count
    const updatedDoc = await getDoc(kichenDoc);

    if (updatedDoc.exists()) {
      orderCount = updatedDoc.data().orderCount;
      orderIdInitials = updatedDoc.data().orderIdInitials;
      fullOrderCount = digitsCount(orderCount);
    } else {
      console.error("Document does not exist.");
      orderCount = 0;
    }

    // Reference to the document you want to create or overwrite
    const collectionRef = doc(db, 'orders', orderId);

    const userFriendlyOrderId = orderIdInitials + fullOrderCount
    const updatedOrder = {
      orderCount: userFriendlyOrderId,
      paymentStatus: "paid",
      orderStatus: "New"
    };
    // Write the document
    await setDoc(collectionRef, updatedOrder, {merge: true});
    return userFriendlyOrderId
    // console.log("Order updated with ID: ", orderId);
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
};
