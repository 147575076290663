import { useSelector } from 'react-redux';
import CheckoutItem from '../../components/checkout-item/checkout-item.component';
import { selectCartItems, selectCartTotal, selectCustomerDetails, selectOrderNote, selectOrderType, selectScheduledTime, selectTableNumber } from '../../store/cart/cart.selector';
import { selectKitchen, selectOnlineOrdersConfigs } from '../../store/kitchen/kitchen.selector';

import './checkout.styles.jsx';
import { BodyText, BottomContainer, ButtonContainer, ButtonText, CheckoutContainer, EmptyCartSubtitleText, EmptyCartText, OrderNoteText, PickupDetailsContainer, Title, Total, TotalContainer } from './checkout.styles.jsx';
import { BUTTON_TYPE_CLASSES } from '../../components/button/button.component';
import PaymentButton from '../../components/payment-button/payment-button.component';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CheckoutDetails from '../../components/checkout-details/checkout-details.component';
import EmptyCartImage from '../../assets/empty-cart.png';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../utils/firebase/firebase.utils';
import { Box } from '@mui/material';
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import OrderTypeBottomSheet from '../../components/order-type-bottom-sheet/order-type-bottom-sheet.component';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { useMediaQuery } from '../../utils/utils.common';
import OrderNoteBottomSheet from '../../components/order-note-bottom-sheet/order-note-bottom-sheet.component';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import OrderCustomerDetailsBottomSheet from '../../components/order-customer-details-bottom-sheet/order-customer-details-bottom-sheet.component';
import { roundedUp } from '../../utils/roundUpNumber';

// TODO: Add error message for customers
const Checkout = () => {
    const [isLoading, setIsLoading] = useState(false);
    const kitchen = useSelector(selectKitchen);
    const cartItems = useSelector(selectCartItems);
    const cartSubtotal = useSelector(selectCartTotal);
    const customerDetails = useSelector(selectCustomerDetails);

    const [showOrderTypeBottomSheet, setShowOrderTypeBottomSheet] = useState(false)
    const [showOrderNoteBottomSheet, setShowOrderNoteBottomSheet] = useState(false)
    const [showOrderCustomerDetailsBottomSheet, setShowOrderCustomerDetailsBottomSheet] = useState(false)

    const getOrderType = useSelector(selectOrderType);
    const getTableNumber = useSelector(selectTableNumber);
    const getScheduledTime = useSelector(selectScheduledTime);
    const orderNote = useSelector(selectOrderNote);
    const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

    const kitchenSuburb = kitchen?.fullAddress ? kitchen?.fullAddress.split(",")[1] : null
    const cardFeePercent = onlineOrdersConfig?.cardFeePercent ? onlineOrdersConfig?.cardFeePercent / 100 : 0
    const cardFeeFixedCharge = onlineOrdersConfig?.cardFeeFixedCharge ? onlineOrdersConfig?.cardFeeFixedCharge / 100 : 0

    const cardTotalFee = roundedUp((cartSubtotal * cardFeePercent) + cardFeeFixedCharge);
    const cartTotal = roundedUp(Number(cartSubtotal) + Number(cardTotalFee))

    const { kitchenSlug } = useParams();

    const navigate = useNavigate();

    const isMobile = useMediaQuery('(max-width: 900px)');

    useEffect(() => {
        logEvent(analytics, "checkout_visited", {
            kitchenName: kitchen?.kitchenName
        });

    }, [kitchen])

    if (cartItems.length === 0) {
        return (
            <CheckoutContainer style={{ textAlign: 'center', justifyContent: 'center', display: 'flex', alignContent: 'center', alignItems: 'center', paddingTop: '72px', paddingBottom: '72px' }}>
                <img src={EmptyCartImage} style={{ width: '150px', paddingBottom: '24px' }} alt='Empty Cart' />
                <EmptyCartText>Your cart is empty</EmptyCartText>
                <EmptyCartSubtitleText>Go back to the menu to add some items.</EmptyCartSubtitleText>
            </CheckoutContainer>
        )
    } else {
        return (
            // <Fragment>
            <div>
                <CheckoutContainer>
                    {!isMobile && (
                        <div style={{ paddingLeft: 24, paddingBottom: 16 }}>
                            <Title>Your Cart</Title>
                        </div>
                    )}
                    {/* Pickup details */}
                    <PickupDetailsContainer onClick={() => setShowOrderTypeBottomSheet(!showOrderTypeBottomSheet)}>
                        {kitchen.fullAddress && (
                            <Box style={{
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}>
                                {getOrderType === "takeaway" && (
                                    <CheckoutDetails icon={<ShoppingBagOutlinedIcon sx={{ color: "#475467" }} fontSize='small' />} title={`Take Away ${getScheduledTime === "ASAP" ? getScheduledTime : `at ${getScheduledTime}`}`} body={`${kitchenSuburb} ${getScheduledTime === "ASAP" ? `(Ready in ${onlineOrdersConfig?.orderReadyTime}mins)` : ""}`} />
                                )}
                                {getOrderType === "dinein" && (
                                    <CheckoutDetails icon={<TableRestaurantIcon sx={{ color: "#475467" }} fontSize='small' />} title={`Dine In ${getTableNumber !== null ? `(Table ${getTableNumber})` : "(Select Table)"}`} body={`${kitchenSuburb}`} />
                                )}
                                <ButtonText>Change</ButtonText>
                            </Box>
                        )}

                    </PickupDetailsContainer>

                    <div style={{ padding: 16, borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: "rgba(174, 174, 174, 0.5)" }}>

                        <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', flex: 1, }}>
                            <div>

                                <span style={{ color: "#353435", fontSize: 16, fontFamily: "Poppins", fontWeight: 500 }}>

                                    Add your details
                                </span>
                            </div>
                            <div
                                style={{
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    background: "#F5F5F5", borderRadius: 68, cursor: 'pointer'
                                }}
                                onClick={() => setShowOrderCustomerDetailsBottomSheet(true)}
                            >

                                <span style={{ color: "#353435", fontSize: 14, fontFamily: "Poppins", fontWeight: 500 }}>
                                    {customerDetails !== undefined && customerDetails?.customerEmail !== null && customerDetails?.customerEmail !== "" && customerDetails?.customerName !== null && customerDetails?.customerName !== "" ? "Update" : "Add"}
                                </span>
                            </div>
                        </div>
                        {customerDetails !== undefined && customerDetails?.customerEmail !== null && customerDetails?.customerEmail !== "" && customerDetails?.customerName !== null && customerDetails?.customerName !== "" && (
                            <div>
                                <span style={{ color: "#353435", fontSize: 14, fontFamily: "Poppins", fontWeight: 500 }}>

                                    {customerDetails?.customerName} {" "}
                                </span>
                                <span style={{ color: "#353435", fontSize: 14, fontFamily: "Poppins", fontWeight: 500 }}>
                                    {customerDetails?.customerMobile && `(${customerDetails.customerMobile})`}
                                </span>
                                <div style={{ color: "#353435", fontSize: 14, fontFamily: "Poppins", fontWeight: 400 }}>
                                    {customerDetails?.customerEmail}
                                </div>
                            </div>
                        )}

                    </div>




                    {cartItems.map((cartItem) => (

                        <CheckoutItem key={cartItem.cartItemId} cartItem={cartItem} />
                    ))}

                    {(orderNote === null || orderNote === '') && (

                        <div style={{
                            display: 'inline-block',
                            background: "#F9FAFB",
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 12,
                            paddingBottom: 12,
                            marginTop: 32,
                            marginBottom: 32,
                            marginLeft: 17,
                            borderRadius: 68,
                            cursor: 'pointer',
                        }}
                            onClick={() => setShowOrderNoteBottomSheet(true)}
                        >
                            <div style={{
                                display: 'flex', alignItems: 'center', gap: 8,
                            }}>
                                <EventNoteOutlinedIcon sx={{ color: "#353435", marginTop: '3px' }} fontSize='small' />
                                <OrderNoteText>
                                    Order Note
                                </OrderNoteText>
                            </div>
                        </div>
                    )}

                    {orderNote !== null && orderNote !== '' && (

                        <div style={{
                            margin: 16,
                            marginTop: 24
                        }}
                            onClick={() => setShowOrderNoteBottomSheet(true)}>

                            <div style={{
                                // alignItems: 'center',
                                display: 'flex',

                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>

                                <div style={{ display: 'flex', gap: 8, }}>

                                    <EventNoteOutlinedIcon sx={{ color: "#353435", marginTop: '3px' }} fontSize='small' />

                                    <div>

                                        <span style={{
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: "#353435"
                                        }}>
                                            Order Note
                                        </span>
                                        <br />
                                        <span style={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: 14,
                                            color: "#353435"
                                        }}>
                                            {orderNote}
                                        </span>
                                    </div>
                                </div>

                                <ButtonText>
                                    Update
                                </ButtonText>
                            </div>
                        </div>
                    )}

                    <TotalContainer>

                        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                            <BodyText>Included GST Amount</BodyText>
                            <Total>${(cartSubtotal / 11).toFixed(2)}</Total>
                        </div>
                        {cardTotalFee !== 0 && (

                            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
                                <BodyText>Card fee</BodyText>
                                <Total>${Number(cardTotalFee)?.toFixed(2)}</Total>
                            </div>
                        )}
                        {/* <Divider style={{ paddingTop: '10px', marginBottom: '10px' }} /> */}
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>

                            <Total>Total</Total>
                            <Total>${cartTotal?.toFixed(2)}</Total>
                        </div>

                    </TotalContainer>
                    <BottomContainer>
                        <ButtonContainer>
                            <PaymentButton buttonType={BUTTON_TYPE_CLASSES.cart} onClick={() => {
                                if ((getTableNumber === null || getTableNumber === '') && getOrderType === "dinein") {
                                    setShowOrderTypeBottomSheet(true)
                                } else if (customerDetails === undefined || customerDetails?.customerEmail === null || customerDetails?.customerEmail === "" || customerDetails?.customerName === null || customerDetails?.customerName === '') {
                                    setShowOrderCustomerDetailsBottomSheet(true)
                                }
                                else {
                                    navigate(`/${kitchenSlug}/checkout/payment`)
                                }
                            }

                            } isLoading={isLoading}
                                style={{ flex: 1 }}
                                text="Continue to Payment"
                            />
                        </ButtonContainer>
                    </BottomContainer>
                </CheckoutContainer >

                {showOrderTypeBottomSheet && (
                    <OrderTypeBottomSheet isOpen={showOrderTypeBottomSheet} toggleBottomSheet={() => setShowOrderTypeBottomSheet(false)} onlineOrdersConfig={onlineOrdersConfig} />
                )}

                {showOrderNoteBottomSheet && (
                    <OrderNoteBottomSheet isOpen={{ showOrderNoteBottomSheet }} toggleBottomSheet={() => setShowOrderNoteBottomSheet(false)} />
                )}
                {showOrderCustomerDetailsBottomSheet && (
                    <OrderCustomerDetailsBottomSheet isOpen={{ showOrderCustomerDetailsBottomSheet }} toggleBottomSheet={() => setShowOrderCustomerDetailsBottomSheet(false)} />
                )}
            </div>
            // </Fragment>

        )
    }

}

export default Checkout;