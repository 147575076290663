import { createSelector } from "reselect";
import { RootState } from "../store";
import { KitchenState } from "./kitchen.reducer";
import { Dishes } from "./kitchen.types";
import { dishArchived } from "../../constants";
import { getCategoryNameFromId } from "../../utils/utils.common";

const selectKitchenReducer = (state: RootState): KitchenState => state.kitchen;

export const selectKitchen = createSelector(
  [selectKitchenReducer],
  (kitchenSlice) => kitchenSlice.kitchen
);

export const selectKitchenOpen = createSelector(
  [selectKitchenReducer],
  (kitchenSlice) => kitchenSlice.kitchenOpen
);

// const groupBy = (xs: any, key: any) => {
//   return xs.reduce((rv: any, x: any) => {
//     (rv[x[key]] = rv[x[key]] || []).push(x);
//     return rv;
//   }, {});
// };

const groupBy = (list: any, keyGetter: any) => {
  const map = new Map();
  list.forEach((item: any, i: number) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    // console.log("COLLECTION: ")
    // console.log(key + " " +item.subcategory)
    if (!collection) {
      // map.set(item.subcategory + " " + key, [item]);
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const selectDishesMap = createSelector(selectKitchen, (kitchen) => {
  const categories = [] as string[];
  const allDishes = [] as Object[];

  if (kitchen?.dishes && Object.keys(kitchen).length > 0 && kitchen !== undefined)  {
    // eslint-disable-next-line array-callback-return
    Object.entries(kitchen?.dishes).map(([key, dish]) => {
      
      if (dish.dishStatus !== dishArchived){
        if (dish.categoryId && kitchen?.dishCategories && kitchen?.dishCategories?.length > 0){
          const globalCategoryName = getCategoryNameFromId(kitchen?.dishCategories,dish.categoryId)
          categories.push(globalCategoryName);
        }else{
          categories.push(dish.category);
        }
        dish["id"] = key;
        allDishes.push(dish);
      }
    });
  }

  const categoriesMap = groupBy(allDishes, (dish: Dishes) => {
    if(dish.categoryId){
      return getCategoryNameFromId(kitchen?.dishCategories, dish.categoryId)
    }
    return dish.category
  });

  const sortedCategories = new Map([...categoriesMap.entries()]);
  return sortedCategories;
  
});

export const selectKitchenIsLoading = createSelector(
  [selectKitchenReducer],
  (kitchenSlice) => kitchenSlice.isLoading
);

export const selectOnlineOrdersConfigs = createSelector(
  [selectKitchenReducer],
  (kitchenSlice) => kitchenSlice.onlineOrdersConfigs
);
