import { useCallback, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { capitalizeFirstLetter, useMediaQuery } from "../../utils/utils.common";
import { HeaderContainer, UnorderedList } from "./menu-headers.style";
import * as Scroll from 'react-scroll';
import './menu-headers.css';

// const getLastItem = () => {
//     const fullPath = window.location.href;
//     const lastPath = fullPath.substring(fullPath.lastIndexOf('/') + 1)
//     const newPath = lastPath.substring(lastPath.lastIndexOf('#') + 1)


//     return newPath
// }

let Link = Scroll.Link;


const MenuHeaders = ({ categoriesMap, allCategories }) => {
    const [top, setTop] = useState(true);

    // console.log(top)
    // const [selected, setSelected] = useState(null)
    // const [element, setElement] = useState(document.querySelector(".active"));

    const isMobile = useMediaQuery('(max-width: 1100px)');
    // const isLargerHeader = useMediaQuery('(max-width: 600px)');

    const sortCategories = () => {
                // Create a mapping from category name to category number
                const categoryMap2 = new Map();
                allCategories?.forEach(category => {
                    categoryMap2.set(category.name, category.categoryNumber);
                });
        
                // Function to get the category number from the map key
                const getCategoryNumber = (category) => {
                    const categoryKey = category;
                    return categoryMap2.get(categoryKey) || Infinity;
                };
        
                // Sort the map keys based on the category number
                const sortedMap = new Map([...categoriesMap.entries()].sort((a, b) => {
                    // console.log(getCategoryNumber(a[0]))
                    return getCategoryNumber(a[0]) - getCategoryNumber(b[0]);
                }));

                
        let content = [];
        for (const [title] of sortedMap) {
            const customTitle = title?.match(/^\d/) ? title.substring(3) : title;

            content.push(
                <div className="nav-scroller_box" style={{ cursor: 'pointer' }}>
                    <Link smooth spy activeClass="active" offset={-72} key={customTitle} id={customTitle} to={customTitle} style={{ color: "#383838", fontSize: '16px' }}>{capitalizeFirstLetter(customTitle)}</Link>
                </div>
                // content.push(<HeaderText key={title} id={`${title}-header`} onClick={() => setSelected(title)} to={`/${kitchenSlug}#${title}`} style={handleStyleButton(title)}>{capitalizeFirstLetter(title)}</HeaderText>)
            )
        }
        // Hide the category header if there is only 1 category
        if (content.length <= 1) {
            return content = []
        }
        return content
    }

    // scroll the active category into view
    const handleClickScroll = useCallback(
        e => {
            e.preventDefault();
            window.pageYOffset > 160 ? setTop(false) : setTop(true)

            // const currentElement = e.currentTarget.document.querySelector(".active");
            // if (element !== currentElement && currentElement!== undefined ) {
            // e.currentTarget.document.querySelector('#categoryList').scrollLeft = e.currentTarget.document.querySelector('a.active').closest('div.nav-scroller_box').offsetLeft - 50
            if (e.currentTarget.document.querySelector('a.active')) {

                e.currentTarget.document.querySelector('#categoryList').scrollTo({
                    left: e.currentTarget.document.querySelector('a.active').closest('div.nav-scroller_box').offsetLeft - 50,
                    behavior: 'smooth',
                })
            }
            // console.log("---------------------------")
            // currentElement.scrolLeft()
            // currentElement.scrollIntoView()
            // }
            // setElement(currentElement)

        }, []
    );

    useEffect(() => {
        // setY(window.scrollY);

        window.addEventListener("scroll", handleClickScroll);

        return () => {
            window.removeEventListener("scroll", handleClickScroll);
        };

    }, [handleClickScroll]);

    const allSortedCategories = sortCategories()

    if (allSortedCategories.length === 0) {
        return null;
    }

    return (
        <HeaderContainer style={{
            boxShadow: top ? 'none' : '0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%)'
        }}>

            <UnorderedList
                id="categoryList"
                style={{
                    width: isMobile ? window.innerWidth - 20 : '100%',
                    // width: window.innerWidth - 20 ,
                    // display: 'flex',
                    overflowX: 'scroll',
                }}>

                {allSortedCategories}
            </UnorderedList>
        </HeaderContainer>

    )
}

export default MenuHeaders;