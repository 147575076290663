import { createSelector } from "reselect";
import { RootState } from "../store";
import { OrderState } from "./order.reducer";

const selectOrderReducer = (state: RootState): OrderState => state.order;

export const selectOrder = createSelector(
  [selectOrderReducer],
  (orderSlice) => orderSlice.order
);


export const selectOrderIsLoading = createSelector(
  [selectOrderReducer],
  (orderSlice) => orderSlice.isLoading
);

export const selectOrderIsError = createSelector(
  [selectOrderReducer],
  (orderSlice) => orderSlice.error
);

export const selectOrderTotal = createSelector([selectOrder], (order) =>
order?.cartItems?.reduce(
    (total, cartItem) => total + cartItem.quantity * cartItem.dishPrice,
    0
  )
);
