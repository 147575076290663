import styled from "styled-components";
import { baseDark500, baseLight } from "../../constants";

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  /* background: white; */
  max-width: 526px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  /* padding-top: 24px; */
  
  @media screen and (max-width: 900px) {
    /* max-width: 100%; */
    margin-top: 50px;
    /* margin-left: 10px;
    margin-right: 10px; */
  }
`;
export const PaymentFormContainer = styled.form`
  /* height: 300px; */
  flex-direction: column;
  flex: 1;
`;
export const ParentContainer = styled.div`
  /* box-shadow: 0px 2px 4px rgba(145, 145, 145, 0.25);
filter: drop-shadow(0px 2px 14px rgba(240, 236, 236, 0.25)); */
padding: 12px;
/* border-radius: 16px; */
background: white;

`


export const TitleText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.172308px;

  color: #000000;
`;
export const FormContainer = styled.form`
  height: 100px;
  min-width: 500px;
`;

export const PaymentMessage = styled.div`
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
`;

export const BodyText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: ${baseDark500};
`