import './menu-item.styles.jsx';

import { BackgroundImage, DescriptionContainer, ImageContainer, MainContainer, NameContainer, PriceContainer, MenuItemContainer } from './menu-item.styles.jsx';
import { useState } from 'react';
import { TransitionsModal } from '../menu-item-modal/menu-item-modal.component.jsx';
import { dishSoldout } from '../../constants.js';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../utils/firebase/firebase.utils';

const MenuItem = ({ product, kitchen }) => {
    const [showModal, setShowModal] = useState(false);
    const toggleShowModal = (e, reason) => {

        logEvent(analytics, "dish_viewed", {
            kitchenName: kitchen?.kitchenName
        });

        setShowModal(p => !p);
    }

    const { dishName, dishDescription, dishPrice, remoteUri, dishStatus } = product;
    // const cartItems = useSelector(selectCartItems);

    // const dispatch = useDispatch();
    // const addProductToCart = () => dispatch(addItemToCart(cartItems, product));
    return (
        <div style={{ display: 'flex' }}>
            <MenuItemContainer onClick={toggleShowModal}>
                {/* <ProductFooterContainer> */}
                <MainContainer>
                    <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column' }}>
                        <NameContainer>{dishName}</NameContainer>
                        <DescriptionContainer>{dishDescription ? dishDescription.replaceAll('\\n', '\n') : ''}</DescriptionContainer>
                    </div>
                    <div style={{ flex: 1, alignItems: 'flex-end', display: 'flex' }}>
                        <div style={{ alignItems: 'center', flex: 1, display: 'flex' }}>

                            <PriceContainer>${Number(dishPrice).toFixed(2)}</PriceContainer>
                            {dishStatus === dishSoldout && (
                                <>
                                    <span style={{ fontSize: 3, color: '#D9D9D9', paddingLeft: 5, paddingRight: 5 }}>{"\u2B24"}</span>
                                    <PriceContainer style={{ fontWeight: 400, color: '#F04438' }}>{dishStatus}</PriceContainer>
                                </>
                            )}
                        </div>

                    </div>
                </MainContainer>
                {remoteUri && (
                    <ImageContainer>
                        <BackgroundImage className='image' imageUrl={remoteUri} alt={`${dishName}`} />
                    </ImageContainer>
                )}
                {/* </ProductFooterContainer> */}
                {/* <AddButton type={BUTTON_TYPE_CLASSES.inverted} onClick={addProductToCart}>Add to cart</AddButton> */}
            </MenuItemContainer>
            {showModal && (
                <TransitionsModal product={product} showModal={showModal} toggleShowModal={toggleShowModal} kitchen={kitchen} />
            )}
        </div>
    )
}

export default MenuItem;