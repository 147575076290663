import styled from "styled-components";

export const CheckoutContainer = styled.div`
  width: 526px;
  /* min-height: 90vh; */
  /* display: flex; */
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  align-content: center;
  /* margin: 50px auto 0; */
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border: 1.5px solid #f6f6f6;
  filter: drop-shadow(0px 4px 14px rgba(174, 174, 174, 0.25));
  border-radius: 16px;
  padding-left: 16px;
  padding-right: 24px;
  padding-top: 24px;
  background-color: white;
  margin-top: 70px;
  margin-bottom: 200px;

  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 16px;
    filter: none;
    border: none;
  }
`;

export const Title = styled.span`
  /* Text M/Medium */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.01em;

  color: #353435;
`;

export const Total = styled(Title)`

  font-weight: 600;
  font-size: 16px;

`;

export const BodyText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.01em;

  /* Base/Dark */

  color: #000000;
`;

export const PickupDetailsContainer = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  width: 100%;

  border-bottom: 1px solid rgba(174, 174, 174, 0.5);

  padding-bottom: 16px;
`;

export const TotalContainer = styled.div`
 padding-bottom: 24px;
 background-color: white;
 
 @media screen and (max-width: 900px) {
    border-top: 1px solid #F6F6F6;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 24px;
    position: fixed;
    bottom: 90px;
    left: 16px;
    right: 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  justify-content: center;
  /* margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px; */
  cursor: pointer;
  /* margin-top: 46px; */
  background-color: white;

  @media screen and (max-width: 900px) {
    max-width: 100%;
    position: fixed;

    margin-top: 55px;
    bottom: 0px;
    left: 16px;
    right: 16px;
  }
`;
