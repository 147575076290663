import { useEffect, useState } from "react";
import { OnlineOrdersConfigs } from "../store/kitchen/kitchen.types";
import { DishCategory } from "./types";

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const d = new Date();
export const getDay = weekday[d.getDay()];

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getRegExp = (type) => {
  let regex = null;
  switch (type) {
    case "email":
      regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;
      break;
    case "password":
      regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      break;
    default:
      break;
  }
  return regex;
};

export const getSentenceFromCamelCase = (message) => {
  let pattern = /[A-Za-z]/g;
  let messages = message.match(pattern);
  let errorMessage = "";
  for (let i = 0; i < messages.length; i++) {
    errorMessage +=
      messages[i] === messages[i].toUpperCase()
        ? " " + messages[i].toLowerCase()
        : messages[i];
  }
  return errorMessage.trim();
};

// export const checkValidation = (errors, data: any) => {
//   const finalErrors = {};
//   Object.keys(data).forEach((key) => {
//     if (data[key] === "" || data[key] === {}) {
//       finalErrors[key] = `Please enter ${getSentenceFromCamelCase(key)}.`;
//     }
//   });
//   Object.keys(errors).forEach((key) => {
//     if (errors[key] !== "") {
//       finalErrors[key] = errors[key];
//     }
//   });
//   return finalErrors;
// };

export const getFileStorageBaseUrl = () => {
  return "https://thumbs.dreamstime.com";
};

export const isOnlineOrderConfigValid = (
  onlineOrdersConfig: OnlineOrdersConfigs
) => {
  if (
    onlineOrdersConfig !== null &&
    (onlineOrdersConfig?.takeAwayEnabled || onlineOrdersConfig?.dineInEnabled) &&
    onlineOrdersConfig?.isTyroLocationIdValid
  ) {
    return true;
  }
  return false;
};

export const getCategoryNameFromId = (categories: DishCategory[], id:string) => {
  const category = categories?.find(category => category.id === id);
  return category ? category.name : null;
};

// Count the number of digits in an integer
export const digitsCount = (n) => {
  const orderCount = n;
  let count = 0;
  let userFriendlyOrderNumber = "0";
  if (n >= 1) ++count;

  while (n / 10 >= 1) {
    n /= 10;
    ++count;
  }

  if (count === 1) {
    userFriendlyOrderNumber = "000" + orderCount;
  }
  if (count === 2) {
    userFriendlyOrderNumber = "00" + orderCount;
  }
  if (count === 3) {
    userFriendlyOrderNumber = "0" + orderCount;
  }
  if (count === 4) {
    return orderCount;
  }
  return userFriendlyOrderNumber;
};

export const getFirstWordAndFirstLetterOfSecondWord = (str:string) => {
  // Split the string by spaces
  const words = str.split(" ");

  // Get the first word
  const firstWord = words[0];

  // Initialize an empty string for the first letter of the second word
  let firstLetterOfSecondWord = "";

  // Check if there is a second word and get its first letter
  if (words.length > 1) {
    firstLetterOfSecondWord = words[1].charAt(0) + ".";
  }

  // Return the result
  return firstWord + " " + firstLetterOfSecondWord;
};