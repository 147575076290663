import styled from 'styled-components';
import Button from '../button/button.component';
import { baseDark500, baseDark600 } from '../../constants';

export const MenuItemContainer = styled.div`
 display: flex;
flex-direction: row;
align-items: flex-start;
padding: 16px;
gap: 16px;
/* max-width: 417px; */
max-width: 100%;
/* height: 140px; */
flex: 1;
background: #FFFFFF;
box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.25);
border-radius: 8px;
cursor: pointer;


  @media screen and (max-width: 600px){
    width: 100%;
    display: flex;
    box-shadow: none;
    border-bottom: 0.5px solid rgba(103, 103, 103, 0.25);
    border-radius: 0px;

  /* padding: 0px; */


  }
`
export const MainContainer = styled.div`
display: flex;
flex: 2;
  /* width: 308px; */
  height: 108px;
  flex-direction: column;
  align-content: space-between;
  @media screen and (max-width: 600px){
    width: 100%;
  }
`
export const ImageContainer = styled.div`
flex: 1;
  width: 112px;
  height: 112px;
  display: flex;
`
export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  border-radius: 8px;

  /* margin: 16px; */
`;


export const DescriptionContainer = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.1px;

white-space: pre-wrap; // converts the \n into new lines

color: ${baseDark500};
/* margin-bottom: 16px; */
display: inline-block;
  /* word-wrap: normal; */
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 5em; /* (Number of lines you want visible) * (line-height) */

  padding-top: 8px;

  @media screen and (max-width: 600px){
    max-height: 3.6em; /* (Number of lines you want visible) * (line-height) */
  }
`

export const NameContainer = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
/* identical to box height */

letter-spacing: 0.1px;

color: ${baseDark600};

`;

export const PriceContainer = styled.span`
  /* width: 100%; */
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
/* identical to box height */

letter-spacing: 0.1px;

color: ${baseDark500};
`;
