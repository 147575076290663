import styled from 'styled-components';
import { baseDark500, baseDark600 } from '../../constants';
export const MainContainer = styled.div`
position: absolute;

bottom: 0;
top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  left: 0;
  right: 0;
  border-radius: 16px;
  padding: 24px;
  background-color: white;

  @media screen and (max-width: 900px){
    width: 100%;
    padding: 16px;
  }
`;


export const Title = styled.span`
/* Text M/Medium */

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 140%;
/* or 22px */

letter-spacing: 0.01em;

color: ${baseDark600};
text-align: center;

@media screen and (max-width: 900px){
    font-size: 20px;
  }
`


export const SubTitle = styled.span`
/* Text M/Medium */

font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 30px;
line-height: 140%;
/* or 22px */

letter-spacing: 0.01em;

color: ${baseDark500};
text-align: center;
@media screen and (max-width: 900px){
    font-size: 18px;
  }
`

export const Image = styled.img`
padding-bottom: 44px;
margin-left: auto;
margin-right: auto;
width: 100%;
`