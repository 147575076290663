import React, { Component, useEffect, useState } from "react";
import { auth } from "../../utils/firebase/firebase.utils";
import Spinner from "../../components/spinner/spinner.component";
import { useSearchParams } from "react-router-dom";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils";
import {
  ChildContiner,
  ParentContiner,
  TitleText,
} from "./reset-password.styles";
import FormInput from "../../components/form-input/form-input.component";
import PaymentButton from "../../components/payment-button/payment-button.component";
import { BUTTON_TYPE_CLASSES } from "../../components/button/button.component";

const mapAuthCodeToMessage = (authCode) => {
  switch (authCode) {
    case "auth/invalid-password":
      return "Password provided is not correct";

    case "auth/invalid-action-code":
      return "Invalid Action Code";
    case "auth/expired-action-code":
      return "Expired Action Code";

    // Many more authCode mapping here...

    default:
      return "";
  }
};

export const ResetPassword = (props) => {
  const [email, setEmail] = useState(null);
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [validCode, setValidCode] = useState(null);
  const [verifiedCode, setVerifiedCode] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [queryParameters] = useSearchParams();
  // const mode = queryParameters.get("mode");
  const actionCode = queryParameters.get("oobCode");

  const buttonText = firstLogin ? "Set Password" : "Reset Password";

  // console.log(actionCode)
  useEffect(() => {
    if (actionCode) {
      // let user;
      // Verify the password reset code is valid.

      verifyPasswordResetCode(auth, actionCode)
        .then(async (email) => {
          // Get user from db to check if first login or not
          // console.log("==========")
          // console.log(email);

          // const userQuery = query(
          //   collection(db, "users"),
          //   where("email", "==", email)
          // );

    
            // console.log("!11111111111111111111")
            // const querySnapshot = await getDocs(userQuery);
            // console.log(querySnapshot)
            // console.log("22222222222222")
            // querySnapshot.forEach((doc) => {
            //   console.log(doc.data())
            //   // doc.data() is never undefined for query doc snapshots
            //   user = doc.data();
            //   return;
            // });
            
            // setFirstLogin(user?.firstLogin ? user.firstLogin : false);
            
            setEmail(email);
            setValidCode(true);
            setVerifiedCode(true);
        
        })
        .catch((err) => {
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
          setError(mapAuthCodeToMessage(err.code));
          setValidCode(false);
          setVerifiedCode(true);
        });
    }
  }, []);

  const handleResetPassword = (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    setIsLoading(true)
    // Save the new password.
    confirmPasswordReset(auth, actionCode, newPassword)
      .then(() => {
        setSuccess(true);
        setIsLoading(false)

        // Password reset has been confirmed and new password updated.
      })
      .catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.'
        setError("Something went wrong. Please try again later");
        setIsLoading(false)
        throw error;
        // setError(error.message);
      });
  };

  if (!verifiedCode && !error) {
    return (
      <div
        style={{
          position: "absolute",
          top: window.innerHeight / 2 - 35,
          left: 0,
          right: 0,
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {success && (
        <ParentContiner>
          <div style={{textAlign: 'center'}}>

          {!firstLogin ? <TitleText>Password changed</TitleText> : <TitleText>Password created</TitleText>}
          <p>You can now sign in with your new password</p>
          </div>
        </ParentContiner>
      )}

      {verifiedCode && validCode && !success && (
        <ParentContiner>
          <ChildContiner>
            {firstLogin ? (
              <TitleText>Create your password</TitleText>
            ) : (
              <TitleText>Reset your password</TitleText>
            )}
            <div>
              Email: <span>{email}</span>
            </div>

            <form onSubmit={handleResetPassword}>
              {error ? <div style={{ color: "red" }}>{error}</div> : ""}

              <FormInput
                label="New Password"
                type="password"
                required
                onChange={(evt) => {
                  setError(null);
                  setNewPassword(evt.target.value);
                }}
                name="password"
                value={newPassword}
              />
              <FormInput
                label="Re-enter new Password"
                type="password"
                required
                onChange={(evt) => {
                  setError(null);
                  setConfirmPassword(evt.target.value);
                }}
                name="password"
                value={confirmPassword}
              />
              <div
                style={{
                  paddingTop: 40,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <PaymentButton
                  buttonType={BUTTON_TYPE_CLASSES.cart}
                  // onClick={() => {}}
                  isLoading={isLoading}
                  style={{ flex: 1 }}
                  text={buttonText}
                  type="submit"
                />
              </div>
              {/* <input type="submit" value="SAVE" /> */}
            </form>
          </ChildContiner>
        </ParentContiner>
      )}

      {verifiedCode && !validCode && !success && (
        <ParentContiner className="ResetPassword">
          <div style={{textAlign: 'center'}}>

          <TitleText>Try resetting your password again</TitleText>
          <p className="error">{error}</p>
          </div>
        </ParentContiner>
      )}
    </>
  );
};

export default ResetPassword;
