import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { addItemToCart, addKitchenIdToCart } from '../../store/cart/cart.action';
import { useDispatch, useSelector } from 'react-redux';
import { selectCartItems } from '../../store/cart/cart.selector';
import { BUTTON_TYPE_CLASSES } from '../button/button.component';
import { isOnlineOrderConfigValid, useMediaQuery } from '../../utils/utils.common';
import { MenuVariants } from '../menu-variants/menu-variants.component';
import { useEffect } from 'react';
import { NoticeContainer, NoticeContainerText, WarningText } from './menu-item-modal.styles';
import { baseDark500, baseDark600, dishSoldout, kitchenClosedAutoSet, kitchenClosedOverride, primaryColor } from '../../constants';
import { selectKitchenOpen, selectOnlineOrdersConfigs } from '../../store/kitchen/kitchen.selector';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../utils/firebase/firebase.utils';

const styles = {
    container: isMobile => ({
        position: isMobile ? null : 'absolute',
        top: '50%',
        left: '50%',
        transform: isMobile ? null : 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '600px',
        height: isMobile ? '100%' : '680px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        padding: 0,
        outline: 0,
        overflowY: 'scroll',
    }),


    quantityStyle: isMobile => ({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '140%',
        /* or 22px */

        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.01em',
        paddingTop: '12px',
        justifyContent: 'center',
        // marginBottom: isMobile ? '50px' : '100px',
    })
};


const buttonContiner = {
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zindex: 1,
    position: '-webkit-sticky',
    // position: 'sticky',
    // position: 'fixed',
    // bottom: 0,
    // left: 0,
    // right: 0,

    paddingBottom: '26px',
    maxWidth: '100%',
    padding: '20px',
}
const buttonStyle = {
    width: "100%",
    height: "62px",
    backgroundColor: primaryColor,
    color: 'white',
    textTransform: 'none',
    borderRadius: 8

}

const disableButtonStyle = {
    width: "100%",
    height: "62px",
    backgroundColor: '#808080',
    color: 'white',
    textTransform: 'none',
    borderRadius: 8

}

export const TransitionsModal = ({ product, showModal, toggleShowModal, kitchen }) => {
    const { dishName, dishDescription, dishPrice, remoteUri, variants, dishStatus } = product;
    const [quantity, setQuantity] = useState(1);
    const [dishPriceWithVariants, setDishPriceWithVariants] = useState(Number(dishPrice));
    const [variantSelected, setVariantSelected] = useState([]);
    const [minimumMandatoryVariants, setMinimumMandatoryVariants] = useState(0);
    const [minimumVariantsSelected, setMinimumVariantsSelected] = useState(0);
    const kitchenId = kitchen?.kitchenId;
    const kitchenOrderStatus = kitchen?.kitchenOrderStatus;
    const kitchenTier = kitchen?.tier;

    const isMobile = useMediaQuery('(max-width: 800px)');
    const dispatch = useDispatch();
    const cartItems = useSelector(selectCartItems);
    const isKitchenOpen = useSelector(selectKitchenOpen)
    const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

    const isButtonDisabled = () => {
        if (minimumVariantsSelected >= minimumMandatoryVariants) {
            return false;
        }
        return true;
    }
    const addProductToCart = () => {

        dispatch(addItemToCart(cartItems, product, quantity, variantSelected, dishPrice, dishPriceWithVariants));
        dispatch(addKitchenIdToCart(kitchenId))
        toggleShowModal();

        // log add to cart event
        logEvent(analytics, "add_to_cart", {
            kitchenName: kitchen?.kitchenName
        });

    }

    const increment = () => {
        setQuantity(quantity + 1);
    }
    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    }

    useEffect(() => {
        let count = 0;
        // eslint-disable-next-line array-callback-return
        variants?.map(variant => {
            if (variant.mandatoryChoice) {
                count += variant.minCounter
            }
        })
        // console.log("Count = " + count)
        // console.log("minimumVariantsSelected = " + minimumVariantsSelected)
        setMinimumMandatoryVariants(count)
        // console.log("count = " + count);
    })


    return (
        <div >
            <Modal

                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showModal}
                onClose={toggleShowModal}
                closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //     timeout: 500,
            // }}

            >
                <Fade in={showModal}>
                    <Box sx={styles.container(isMobile)}>
                        <Box style={{flex: 1, display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'scroll'}}>
                            <Box>
                                <Box onClick={toggleShowModal} style={{ position: 'absolute', left: '15px', top: '15px' }}>
                                    <CancelIcon style={{ color: primaryColor, width: '36px', height: '36px', cursor: 'pointer' }} />
                                </Box>
                                <img src={remoteUri} style={{ objectFit: 'cover', width: '100%', maxHeight: '300px' }} />
                                <Box style={{ paddingLeft: 20, paddingRight: 20, paddingTop: remoteUri ? 20 : 60, }}>

                                    <Typography id="transition-modal-title" variant="h6" component="h2" color={baseDark600}>
                                        {dishName}
                                    </Typography>
                                    <Typography id="transition-modal-title" component="h3" color={baseDark500}>
                                        ${Number(dishPrice).toFixed(2)}
                                    </Typography>
                                    <Typography id="transition-modal-description" sx={{ mt: 2, whiteSpace: 'pre-wrap' }} color={baseDark500}>
                                        {dishDescription?.replaceAll('\\n', '\n')}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box sx={{  paddingBottom: 20 }}>

                                {product.variants?.map(
                                    (
                                        {
                                            options,
                                            variantName: optionCategory,
                                            minCounter,
                                            maxCounter,
                                            mandatoryChoice
                                        },
                                        index
                                    ) => {
                                        return (
                                            <MenuVariants
                                                key={index}
                                                setDishPriceVariant={setDishPriceWithVariants}
                                                setVariantSelected={setVariantSelected}
                                                variantSelected={variantSelected}
                                                dishPriceVariant={dishPriceWithVariants}
                                                setMinimumVariantsSelected={setMinimumVariantsSelected}
                                                maxCounter={maxCounter}
                                                minCounter={minCounter}
                                                optionCategory={optionCategory}
                                                optionNumber={index}
                                                options={options}
                                                mandatoryChoice={mandatoryChoice}
                                                kitchenTier={kitchenTier}
                                            />
                                        );
                                    }
                                )}
                            </Box>

                            <Box sx={{
                                borderTop: 1,
                                borderColor: "#f2f2f1",
                                // display: 'flex',
                                flexDirection: 'column',
                                position: isMobile ? 'fixed' : 'fixed',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                background: 'white'
                            }}>

                                { isOnlineOrderConfigValid(onlineOrdersConfig) && (


                                    kitchenOrderStatus === kitchenClosedAutoSet && (
                                        isKitchenOpen ? (
                                            dishStatus !== dishSoldout ? (


                                                <>


                                                    <div style={buttonContiner}>
                                                        {isButtonDisabled() && (
                                                            <div style={{ textAlign: 'center', paddingBottom: 10 }}>
                                                                <WarningText>Required choices missing</WarningText>
                                                            </div>
                                                        )}

                                                        <Box sx={{ display: 'flex', gap: 5 }}>

                                                            <Box sx={styles.quantityStyle(isMobile)}>
                                                                <RemoveCircleIcon onClick={decrement} fontSize={"large"} />
                                                                <Typography style={{ paddingRight: '18px', paddingLeft: '18px', fontSize: 18 }}>{quantity}</Typography>
                                                                <AddCircleIcon onClick={increment} fontSize={"large"} />
                                                            </Box>
                                                            <Button disabled={isButtonDisabled()} style={isButtonDisabled() ? disableButtonStyle : buttonStyle} type={BUTTON_TYPE_CLASSES.base} onClick={addProductToCart}>Add {quantity} to Cart ${dishPriceWithVariants * quantity}</Button>
                                                        </Box>

                                                    </div>
                                                </>
                                            ) : (
                                                <NoticeContainer>
                                                    <NoticeContainerText>Sold Out</NoticeContainerText>
                                                </NoticeContainer>

                                            )
                                        ) : (
                                            <NoticeContainer>
                                                <NoticeContainerText>Venue is currently closed</NoticeContainerText>
                                            </NoticeContainer>
                                        )
                                    )

                                )}

                                 {(isOnlineOrderConfigValid(onlineOrdersConfig)) && (

                                    kitchenOrderStatus !== kitchenClosedAutoSet && (
                                        (kitchenOrderStatus === kitchenClosedOverride) ? (
                                            <NoticeContainer>
                                                <NoticeContainerText>Venue temporarily not accepting online orders</NoticeContainerText>
                                            </NoticeContainer>

                                        ) : (
                                            <>

                                                <div style={buttonContiner}>
                                                    {isButtonDisabled() && (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <WarningText>Required choices missing</WarningText>
                                                        </div>
                                                    )}
                                                    <Box sx={{ display: 'flex', gap: 5 }}>

                                                        <Box sx={styles.quantityStyle(isMobile)}>
                                                            <RemoveCircleIcon onClick={decrement} fontSize={"large"} />
                                                            <Typography style={{ paddingRight: '18px', paddingLeft: '18px', fontSize: 18 }}>{quantity}</Typography>
                                                            <AddCircleIcon onClick={increment} fontSize={"large"} />
                                                        </Box>

                                                        <Button disabled={isButtonDisabled()} style={isButtonDisabled() ? disableButtonStyle : buttonStyle} type={BUTTON_TYPE_CLASSES.base} onClick={addProductToCart}>Add {quantity} to Cart ${dishPriceWithVariants * quantity}</Button>
                                                    </Box>

                                                </div>
                                            </>
                                        ))

                                )}


                            </Box>
                        </Box>
                    </Box>

                </Fade>
            </Modal>
        </div>
    );
}