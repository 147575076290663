import styled from 'styled-components';

export const FooterContainer = styled.div`
text-align: center;
margin-bottom: 100px;

`

export const PageBreak = styled.div`
max-width: 774.01px;
height: 0px;
left: 333px;
top: 2036px;

border: 0.5px solid #C7C7C7;
margin-left: auto;
margin-right: auto;

@media screen and (max-width: 600px){
    border: 0px;
  }
`

export const RestaurantNameContainer = styled.div`
    padding-top: 23px;
    display: flex;
    justify-content: space-around;
    align-items: center;

@media screen and (max-width: 600px ){
    display: block;
    justify-content: space-around;
    padding-top: 10px;


}

`
export const RestaurantName = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.38px;

color: #000000;
padding-left: 10px;

@media screen and (max-width: 600px) {
font-size: 12px;
line-height: 18px;
}

`
export const PoweredByContainer = styled.div`
flex-direction: row;
display: flex;
align-items: center;

/* padding-top: 10px; */
padding-left: 10px;
padding-top: 10px;

@media screen and (max-width: 600px) {
    justify-content: center;
}
`
export const PoweredByText = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;

letter-spacing: 0.38px;

color: #000000;

@media screen and (max-width: 600px) {
font-size: 12px;
line-height: 18px;
}
`
