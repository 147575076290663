import { createSelector } from "reselect";
import { RootState } from "../store";
import { CartState } from "./cart.reducer";

const selectCartReducer = (state: RootState): CartState => state.cart;
export const selectCartItems = createSelector(
  [selectCartReducer],
  (cart) => cart.cartItems
);

export const selectCartKitchenId = createSelector(
  [selectCartReducer],
  (cart) => cart.kitchenId
);

export const selectOrderType = createSelector(
  [selectCartReducer],
  (cart) => cart.orderType
);

export const selectOrderNote = createSelector(
  [selectCartReducer],
  (cart) => cart.orderNote
);

export const selectCustomerDetails = createSelector(
  [selectCartReducer],
  (cart) => cart.customerDetails
);

export const selectTableNumber = createSelector(
  [selectCartReducer],
  (cart) => cart.tableNumber
);

export const selectScheduledTime = createSelector(
  [selectCartReducer],
  (cart) => cart.scheduledTime
);


export const selectCartCount = createSelector([selectCartItems], (cartItems) =>
  cartItems.reduce((total, cartItem) => total + cartItem.quantity, 0)
);

export const selectCartTotal = createSelector([selectCartItems], (cartItems) =>
  cartItems.reduce(
    (total, cartItem) => total + cartItem.quantity * cartItem.dishPriceWithVariants,
    0
  )
);
