import { createRef, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  selectKitchen,
  selectOnlineOrdersConfigs,
} from "../../store/kitchen/kitchen.selector";
import {
  selectCartCount,
  selectCartItems,
  selectCartTotal,
  selectCustomerDetails,
  selectOrderNote,
  selectOrderType,
  selectScheduledTime,
  selectTableNumber,
} from "../../store/cart/cart.selector";
import {
  analytics,
  createOnlineOrder,
  functions,
  updateOnlineOrder,
} from "../../utils/firebase/firebase.utils";
import { logEvent } from "firebase/analytics";
import {
  CheckoutContainer,
  EmptyCartSubtitleText,
  EmptyCartText,
} from "../checkout/checkout.styles";
// import emptyCartImage from "../assets/empty-cart.png";
import Spinner from "../../components/spinner/spinner.component";
import { connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import {
  BodyText,
  ParentContainer,
  PaymentContainer,
  PaymentFormContainer,
  PaymentMessage,
  TitleText,
} from "./payment-form.styles";
import PaymentButton from "../../components/payment-button/payment-button.component";
import { BUTTON_TYPE_CLASSES } from "../../components/button/button.component";
import FormInput from "../../components/form-input/form-input.component";
import { Box } from "@mui/material";
import CheckoutItem from "../../components/checkout-item/checkout-item.component";
import { Link, useNavigate, useParams } from "react-router-dom";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import {
  clearCart,
  setOrderNote,
  setOrderType,
  setScheduledTime,
  setTableNumber,
} from "../../store/cart/cart.action";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import tyroLogo from "../../assets/tyro-logo.png";
import EmptyCartImage from "../../assets/empty-cart.png";
import {
  googleMerchantId,
  tyroLiveMode,
} from "../../utils/firebase/configKeys";
import { getFirstWordAndFirstLetterOfSecondWord } from "../../utils/utils.common";
import { roundedUp } from "../../utils/roundUpNumber";
import { Order } from "../../store/order/order.types";

export const defaultSettings = {
  autoPaySecret: true,
  paySecret: "",
  theme: "default",
  styleProps: {
    bodyBackgroundColor: "#fff",
    errorFontColor: "#F04438",
    // labelPosition: "floating",
    labelFontColor: "#344054",
    inputFocusFontColor: "#353435",
    inputFocusBorderColor: "#353435",
    inputFocusBorderSize: "2px",
    inputFontColor: "#353435",
    walletPaymentsDividerEnabled: true,
    walletPaymentsButtonsStacking: "VERTICAL",
    inputPadding: "",
    inputSpacing: "",
    fontFamily: "roboto",
    bodyMinWidth: 250,
    bodyMaxWidth: 980,
    labelPosition: "block",
    applePayButton: {
      buttonStyle: "black",
    },
  },
  options: {
    applePay: {
      enabled: true,
      totalLabel: "Swifti",
    },
    googlePay: {
      enabled: true,
      merchantInfo: {
        merchantName: "Swifti",
        merchantId: googleMerchantId,
      },
    },
  },
};

// const defaultFormFields = {
//   name: "",
//   email: "",
//   mobile: "",
// };

const tyroJSRef = createRef<any>();
const Payment = () => {
  const [configuration, setConfiguration] = useState(defaultSettings);
  const [error, setError] = useState<{
    type: string;
    message: string;
    errorCode: number;
    debug: any;
  }>({ type: null, message: null, errorCode: null, debug: null });
  const [loading, setLoading] = useState(true);
  const [libraryReady, setLibraryReady] = useState(false);
  const [fetchingPaySecret, setFetchingPaySecret] = useState(false);
  const [tyroPayRequestId, setTyroPayRequestId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const [isFormLoading, setIsFormLoading] = useState(true);

  const [payRequestReady, setPayRequestReady] = useState(false);
  const [payFormReady, setPayFormReady] = useState(false);
  const [submittingOverlay, setSubmittingOverlay] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [payComplete, setPayComplete] = useState(false);
  const cartCount = useSelector(selectCartCount);

  // const [formFields, setFormFields] = useState(defaultFormFields);
  // const { name, email, mobile } = formFields;

  const kitchen = useSelector(selectKitchen);
  const cartItems = useSelector(selectCartItems);
  const cartSubtotal = useSelector(selectCartTotal);
  // const cardFee = (cartSubtotal * 0.0175).toFixed(2);
  const customerDetails = useSelector(selectCustomerDetails);

  const getOrderType = useSelector(selectOrderType);
  const getTableNumber = useSelector(selectTableNumber);
  const getScheduledTime = useSelector(selectScheduledTime);
  const orderNote = useSelector(selectOrderNote);

  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
  const cardFeePercent = onlineOrdersConfig?.cardFeePercent ? onlineOrdersConfig?.cardFeePercent/100 : 0
  const cardFeeFixedCharge= onlineOrdersConfig?.cardFeeFixedCharge ? onlineOrdersConfig?.cardFeeFixedCharge/100 : 0

  const cardTotalFee = roundedUp((cartSubtotal * cardFeePercent) + cardFeeFixedCharge);
  const cartTotal = roundedUp(cartSubtotal + cardTotalFee);

  let totalQuantity =
    cartItems && cartItems.length > 0
      ? cartItems.reduce((acc, cartItem) => acc + cartItem.quantity, 0)
      : 0;

  const navigate = useNavigate();
  const { kitchenSlug } = useParams();

  const dispatch = useDispatch();

  // log payment page visited
  useEffect(() => {
    logEvent(analytics, "payment_page_visited", {
      kitchenName: kitchen?.kitchenName,
    });
  }, [kitchen]);

  const { paySecret, theme, styleProps, options } = configuration;

  const customerName = customerDetails?.customerName
    ? getFirstWordAndFirstLetterOfSecondWord(customerDetails?.customerName)
    : null;

  const customerEmail = customerDetails?.customerEmail
    ? customerDetails.customerEmail
    : null;
  const customerMobileNumber = customerDetails?.customerMobile
    ? customerDetails.customerMobile
    : null;

  let orderType = null;
  if (getOrderType === "dinein") {
    orderType = "Dine In";
  } else {
    orderType = "Take Away";
  }

  // Order object to write
  const order: Order = {
    abn: kitchen?.abn,
    cartItems,
    cartSubtotal,
    createdAt: new Date(),
    customerDetails: {
      customerMobileNumber,
      customerName: customerName,
      customerEmail,
    },
    kitchenAddress: kitchen?.fullAddress,
    kitchenEmail: kitchen?.email,
    kitchenId: kitchen?.kitchenId,
    kitchenName: kitchen?.kitchenName,
    kitchenPhoneNumber: kitchen?.phoneNumber,
    kitchenPushToken: kitchen?.kitchenPushToken
      ? kitchen.kitchenPushToken
      : null,
    orderMethod: "online",
    orderPayedAt: new Date(),
    orderStatus: "New_Order_Processing",
    orderType: orderType,
    orderWaitTime: onlineOrdersConfig?.orderReadyTime
      ? onlineOrdersConfig.orderReadyTime
      : 15,
    paymentStatus: "unpaid",
    paymentType: "card",
    postPay: false,
    scheduledPickupTime: getScheduledTime,
    tableNumber: getTableNumber,
    totalAmount: cartTotal, // with all fees (hoilday surcharge, etc..)
    totalQuantity,
    tyroLocationID: onlineOrdersConfig?.tyroLocationId,
    tyroPayRequestId,
    orderId,
    orderNote,
    onlineCardFeeAmount: cardTotalFee
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;

  //   setFormFields({ ...formFields, [name]: value });
  // };

  let tyro = useRef();
  // STEP 1, Attach TyroJS Library
  // Installs the TyroJS Library onto the DOM
  useEffect(() => {
    // console.log("STEP 1 STARTED");

    const script = document.createElement("script");
    script.id = "tyro-js-library";
    script.src = "https://pay.connect.tyro.com/v1/tyro.js";
    script.crossOrigin = "anonymous";
    script.async = true;
    script.onload = () => setLibraryReady(true);
    document.body.appendChild(script);

    // console.log("STEP 1 DONE");
  }, []);

  // STEP 2, Fetch the Pay Secret
  useEffect(() => {
    setError(null);
    if (
      !paySecret?.length &&
      !fetchingPaySecret &&
      cartItems.length > 0 &&
      customerDetails !== undefined &&
      customerDetails?.customerName !== null
    ) {
      // console.log("STEP 2 STARTED");

      fetchPaySecret();
      // console.log("STEP 2 DONE");
    } else {
      setLoading(false);
    }
  }, [paySecret]);

  // Fetch the Pay Secret
  // This would be replaced with your own implementation to obtain your pay request
  const fetchPaySecret = async () => {
    setFetchingPaySecret(true);
    try {
      const callFunction = httpsCallable(
        functions,
        "tyroEndpoints-createOrder"
      );

      console.log(cartTotal)

      const response: any = await callFunction({
        cartTotal: cartTotal,
        tyroLocationID: onlineOrdersConfig?.tyroLocationId,
      });

      const responseStatus = response.data.statusCode;

      if (responseStatus !== 200) {
        setLoading(false);
        setError({
          type: "critical",
          errorCode: responseStatus,
          message:
            "Error occurred while loading checkout page. Please try again later",
          debug: { response },
        });
      }
      // console.log(responseStatus);
      const paySecret = response.data.paySecret;
      const payRequestId = response.data?.payRequestId
        ? response.data.payRequestId
        : null;
      const orderId = response.data?.orderId ? response.data.orderId : null;

      setOrderId(orderId);
      setTyroPayRequestId(payRequestId);
      setConfiguration({
        ...configuration,
        paySecret,
      });
    } catch (error: any) {
      setError({
        type: "critical",
        errorCode: error.errorCode,
        message: "Failed to generate Payment form. Please try again later.",
        debug: { error },
      });
      setLoading(false);
      setIsFormLoading(false);
    }
    setFetchingPaySecret(false);
  };

  // STEP 3, Load the Pay Request into TyroJS
  useEffect(() => {
    if (libraryReady && paySecret?.length) {
      initPayRequest();
      // console.log("STEP 3 DONE");
    }
  }, [libraryReady, paySecret]);

  // Initialize Tyro.js with the Pay Request
  const initPayRequest = async () => {
    setLoading(true);
    setPayRequestReady(false);
    setPayFormReady(false);
    setSubmitting(false);
    let orderId = null;

    try {
      // @ts-ignore
      tyro.current = new window.Tyro({
        liveMode: tyroLiveMode,
      });

      // @ts-ignore
      await tyro.current.init(paySecret);

      const payFormElement = document.getElementById("tyro-pay-form");
      if (payFormElement !== null) {
        payFormElement.innerHTML = "";
      }
      // @ts-ignore
      const payForm = tyro.current.createPayForm({
        theme,
        styleProps,
        options,
      });
      setLoading(false);

      payForm.setWalletPaymentBeginListener(async (paymentType) => {
         orderId = await createOnlineOrder(order)

        setError(null);
        setSubmittingOverlay(true);
        setSubmitting(true);

        if (paymentType === "APPLE_PAY") {
          // optionally do something specific to Apple Pay
        } else if (paymentType === "GOOGLE_PAY") {
          // optionally do something specific to Google Pay
        }
      });
      payForm.setWalletPaymentCancelledListener((paymentType) => {
        setSubmittingOverlay(false);
        setSubmitting(false);

        if (paymentType === "APPLE_PAY") {
          // optionally do something specific to Apple Pay
        } else if (paymentType === "GOOGLE_PAY") {
          // optionally do something specific to Google Pay
        }
      });

      payForm.setWalletPaymentCompleteListener((paymentType, error) => {
        if (error) {
          setError({
            type: "critical",
            errorCode: error.errorCode,
            message: `${error.toString()}`,
            debug: { error },
          });
          setSubmittingOverlay(false);
          setSubmitting(false);
        } else {
          getPaymentResult(orderId);
        }
      });

      // Javascript code sample
      payForm.setReadyListener(() => {
        // your code to handle presentation, animations or show the pay button
        setIsFormLoading(false);
      });

      payForm.inject("#tyro-pay-form");
      setPayRequestReady(true);
      setPayFormReady(true);
      // console.log("STEP 4 READY");
    } catch (error: any) {
      // console.log("ERROR HERE ====>");
      setError({
        type: "critical",
        errorCode: error.errorCode,
        message: `${error.toString()}`,
        debug: { error },
      });
      setLoading(false);
    }
  };

  // STEP 5, Handle submitting the payment
  async function submitPayForm(event) {
    event.preventDefault(); // Prevent the default form submission behavior

    // if (name !== null && name !== "" && email !== null && email !== "") {
    setError(null);
    setSubmitting(true);
    let result;
    try {
      // write order
      const orderId = await createOnlineOrder(order);
      // @ts-ignore
      result = await tyro.current.submitPay();

      await getPaymentResult(orderId);
    } catch (error: any) {
      if (error?.type === "CLIENT_VALIDATION_ERROR" && !error?.errorCode) {
        // can ignore these errors as handled by validation
      } else {
        // display other errors
        setError({
          type: "critical",
          errorCode: error.errorCode,
          message: `${error.toString()}`,
          debug: { error, result },
        });
      }
      setSubmitting(false);
    }
  }

  // Used to fetch the Pay Request Response
  async function getPaymentResult(orderId: string) {
    // @ts-ignore
    const payRequest = await tyro.current.fetchPayRequest();

    if (payRequest.status === "SUCCESS") {
      // update order in the db after successful payment

      updateOnlineOrder(orderId,order)
        .then((orderCount: string) => {
          // TODO: Add check for response status
          // console.log("tyroEndpoints-createOrderDB ");
          // console.log(response);

          setSubmitting(false);

          const orderType = "takeaway";
          const scheduledTime = "ASAP";
          dispatch(setOrderType(orderType));
          dispatch(setOrderNote(null));
          dispatch(setTableNumber(null));
          dispatch(setScheduledTime(scheduledTime));
          dispatch(clearCart());

          // Reroute to success page
          navigate(`/${kitchenSlug}/checkout/success`, {
            state: { order: {...order, orderCount} },
          });
        })
        .catch((err) => {
          throw err;
        });
    } else {
      setError({
        type: "invalid",
        errorCode: payRequest.errorCode,
        message: `${payRequest.status}`,
        debug: { payRequest },
      });
      setSubmitting(false);
    }
    setSubmittingOverlay(false);
  }

  if (cartItems.length !== 0 && loading) {
    return (
      <div
        style={{
          position: "absolute",
          left: window.innerWidth / 2 - 35,
          top: window.innerHeight / 2 - 35,
        }}
      >
        <Spinner size={undefined} color={undefined} />
      </div>
    );
  }

  if (cartItems.length === 0) {
    return (
      <CheckoutContainer
        style={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          paddingTop: "72px",
          paddingBottom: "72px",
        }}
      >
        <img
          src={EmptyCartImage}
          style={{ width: "150px", paddingBottom: "24px" }}
          alt="Empty Cart"
        />
        <EmptyCartText>Your cart is empty</EmptyCartText>
        <EmptyCartSubtitleText>
          Go back to the menu to add some items.
        </EmptyCartSubtitleText>
      </CheckoutContainer>
    );
  }

  if (
    customerDetails === undefined ||
    customerDetails?.customerName === null ||
    customerDetails?.customerEmail === null
  ) {
    return (
      <CheckoutContainer
        style={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          paddingTop: "72px",
          paddingBottom: "72px",
        }}
      >
        {/* <img src={EmptyCartImage} style={{ width: '150px', paddingBottom: '24px' }} alt='Empty Cart' /> */}
        <EmptyCartText>Some of your details are missing</EmptyCartText>
        <EmptyCartSubtitleText>
          Go back to the checkout page to add them
        </EmptyCartSubtitleText>
      </CheckoutContainer>
    );
  }

  // alert(error.message)

  return (
    <Box>
      <PaymentContainer ref={tyroJSRef}>
        <PaymentFormContainer onSubmit={submitPayForm}>
          {/* <div
              id="pay-form-submitting-overlay"
              style={{
                display: submittingOverlay ? "flex" : "none",
                // position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255,255,255,0.5)",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 12,
              }}
            >
              ... Submitting ...
            </div> */}

          <div
            style={{
              paddingLeft: 12,
              paddingRight: 12,
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              background: "#F9F9F9",
              alignItems: "center",
            }}
          >
            <Link
              style={{ display: "flex", flex: 1, gap: 5, alignItems: "center" }}
              to={`/${kitchenSlug}/checkout`}
            >
              <span style={{ marginTop: 3 }}>
                {getOrderType === "takeaway" ? (
                  <ShoppingBagOutlinedIcon
                    sx={{ color: "#353435" }}
                    fontSize="small"
                  />
                ) : (
                  <TableRestaurantIcon
                    sx={{ color: "#475467" }}
                    fontSize="small"
                  />
                )}
              </span>

              <p>
                <span
                  style={{
                    color: "#353435",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  {getOrderType === "takeaway"
                    ? "Take Away"
                    : `Table ${getTableNumber}`}
                </span>
                <span
                  style={{
                    margin: "0 5px",
                    color: "#888",
                    fontSize: "20px",
                    verticalAlign: "middle",
                  }}
                >
                  •
                </span>

                <span
                  style={{
                    color: "#353435",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 14,
                  }}
                >
                  ${cartTotal} (
                  {cartCount === 1 ? `${cartCount} Item` : `${cartCount} Items`}
                  )
                </span>
              </p>
            </Link>

            <Link
              style={{
                color: "#8600FF",
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: 14,
              }}
              to={`/${kitchenSlug}/checkout`}
            >
              See items
            </Link>
          </div>
          {/* <ParentContainer>
            <TitleText>Your Details</TitleText>
            <FormInput
              label="Name"
              type="string"
              required
              onChange={handleChange}
              name="name"
              value={name}
              aria-label="Please enter a name"
              aria-required={true}
              // style={{marginTop: 10}}
            />
            <FormInput
              label="Email"
              type="email"
              required
              onChange={handleChange}
              name="email"
              value={email}
              // style={{marginTop: 10}}
            />
            <FormInput
              label="Mobile (Optional)"
              type="number"
              onChange={handleChange}
              name="mobile"
              value={mobile}
              // style={{marginTop: 10}}
            />
          </ParentContainer> */}
          <div style={{ marginTop: "16px", marginBottom: "16px" }} />

          {/* <ParentContainer> */}
          {/* <TitleText>Order summary</TitleText>

              {cartItems.map((cartItem) => (
                <CheckoutItem key={cartItem.cartItemId} cartItem={cartItem} />
              ))} */}

          {/* <Box
                sx={{
                  paddingTop: "16px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <BodyText>Subtotal</BodyText>
                  <BodyText>${cartSubtotal.toFixed(2)}</BodyText>
                </Box> */}

          {/* <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '10px'
              }}>
              <BodyText>Card Fee</BodyText>
              <BodyText>${cardFee}</BodyText>
            </Box> */}

          {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <TitleText style={{ fontSize: 20 }}>Total</TitleText>
                  <TitleText style={{ fontSize: 20 }}>
                    ${cartTotal?.toFixed(2)}
                  </TitleText>
                </Box>
              </Box>
            </ParentContainer> */}
          <div style={{ marginTop: "16px", marginBottom: "16px" }} />

          <ParentContainer>
            <TitleText>Almost There {customerDetails?.customerName}!</TitleText>
            <div style={{ marginTop: "12px", marginBottom: "12px" }} />

            <div style={{ visibility: loading ? "visible" : "hidden" }}>
              {" "}
              ... Loading ...{" "}
            </div>
            {/*<PaymentElement id="payment-element" options={paymentElementOptions} onReady={() => setIsElementsLoading(false)} />*/}
            <div id="pay-form">
              <div id="tyro-pay-form"></div>
              {/* <button id="pay-form-submit">Pay</button> */}
            </div>
          </ParentContainer>
          {/* {(stripe && elements) && ( */}
          {/* )} */}
          {/* Show any error or success messages */}
          {/* {message && <PaymentMessage>{message}</PaymentMessage>} */}

          <Box
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingBottom: "16px",
            }}
          >
            {error?.type && (
              <div
                className={"error-container"}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  background: "#FFF6F6",
                  borderLeft: "3px solid #FF6961",
                  paddingTop: 13,
                  paddingBottom: 13,
                  paddingLeft: 24,
                  paddingRight: 24,
                }}
              >
                <p
                  style={{
                    color: "#000000",
                    fontWeight: 400,
                    fontSize: 14,
                    fontFamily: "Poppins",
                  }}
                >
                  {error.message}
                </p>
              </div>
            )}
            <PaymentButton
              id="pay-form-submit"
              buttonType={BUTTON_TYPE_CLASSES.cart}
              text="Pay now"
              disabled={submitting}
              style={{
                width: "100%",
                marginTop: 24,
                visibility: payRequestReady ? "visible" : "hidden",
              }}
              isLoading={undefined}
              cartItems={undefined}
              onClick={undefined}
            />

            {error === null && (
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  verticalAlign: "middle",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 20,
                }}
              >
                <span
                  style={{
                    color: "#344054",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 12,
                    paddingRight: 5,
                  }}
                >
                  Secure payments powered by
                </span>
                <img src={tyroLogo} alt="logo" height="22px" />
              </div>
            )}
          </Box>
        </PaymentFormContainer>
      </PaymentContainer>

      <Box
        style={{
          display: isFormLoading || submitting ? "flex" : "none",
          background: "#00000040",
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        <Box
          style={{
            // display: 'flex',
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#EAECF0",
            borderRadius: 8,
            padding: 12,
            //  width: '100%',
            //  height: '100%'
          }}
        >
          <Spinner size={undefined} color={undefined} />
          {isFormLoading && <p style={{ textAlign: "center" }}>Loading...</p>}
          {submitting && (
            <>
              <p style={{ textAlign: "center" }}>Please wait.</p>
              <p>Submitting Payment</p>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Payment;
