import styled from "styled-components";
import { baseDark600 } from "../../constants";

const TextBase = styled.span`
font-family: 'Poppins';
font-style: normal;
letter-spacing: 0.38px;
color: ${baseDark600};
`
export const KitchenName = styled(TextBase)`
font-weight: 500;
font-size: 32px;
line-height: 44px;
color: #000000;
@media screen and (max-width: 900px) {
  font-size: 24px;
  line-height: 33px;
}
`
export const Header = styled(TextBase)`
font-weight: 500;
font-size: 24px;
@media screen and (max-width: 900px) {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
`


export const BodyText = styled(TextBase)`
font-weight: 400;
font-size: 24px;
@media screen and (max-width: 900px) {
  font-size: 20px;
  line-height: 30px;
}
`