import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavigationContainerMain = styled.div`
box-sizing: border-box;
display: flex;

position: absolute;
height: 88px;
width: 100%;
left: 0px;
top: 0px;

background: #FFFFFF;
border-bottom: 1.5px solid #F6F6F6;
box-shadow: 0px 2px 4px rgba(145, 145, 145, 0.25);
filter: drop-shadow(0px 2px 14px rgba(163, 163, 163, 0.25));
z-index: 2;

`
export const NavigationContainer = styled(NavigationContainerMain)`
  height: 70px;
  /* top:  ${({ signupPage }) => `${signupPage} ? "88px" : "0px"`}; */


  /* justify-content: flex-end; */
  /* align-items: center;
  align-content: center; */

  /* filter: drop-shadow(0px 2px 14px rgba(163, 163, 163, 0.25)); */

  @media screen and (max-width: 900px) {
    height: 70px;
    /* padding: 10px; */
    /* margin-bottom: 20px; */
  }
`
export const LogoContainer = styled(Link)`
  height: 100%;
  padding: 25px;
  border-right: 1px solid #F6F6F6;
  margin-right: 50px;

  @media screen and (max-width: 900px) {
    padding-left: 0px;
    margin-right: 10px;

  }
`
export const NavLinks = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 10px;
  @media screen and (max-width: 900px) {
    width: 80%;
  }
`

export const Title = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;
/* identical to box height, or 144% */
text-align: center;
letter-spacing: 0.2px;
/* padding-bottom: 5px; */
padding-left: 10px;

color: #000000;

@media screen and (max-width: 900px) {
  font-size: 16px;
  line-height: 24px;
  }

`
export const Subtitle = styled(Title)`
  font-weight: 400;
  font-size: 14px;
`