import { AnyAction } from "redux";
import {
  fetchOrderFailed,
  fetchOrderStart,
  fetchOrderSuccess,
} from "./order.action";
import { Order } from "./order.types";

export type OrderState = {
  readonly order: Order;
  readonly isLoading: boolean;
  readonly error: Error | null;
};
export const KITCHEN_INITIAL_STATE: OrderState = {
  order: {} as Order,
  isLoading: false,
  error: null,
};

export const orderReducer = (
  state = KITCHEN_INITIAL_STATE,
  action: AnyAction
): OrderState => {
  // if (fetchOrderStart.match(action)) {
  //   return { ...state, isLoading: true };
  // }

  // if (fetchOrderSuccess.match(action)) {
  //   return { ...state, order: action.payload, isLoading: false };
  // }

  // if (fetchOrderFailed.match(action)) {
  //   return { ...state, error: action.payload, isLoading: false, order: {} as Order };
  // }

  return state;
};
