import { useDispatch, useSelector } from 'react-redux';
import CheckoutItem from '../../components/checkout-item/checkout-item.component';

import './checkout-confirmation.styles.jsx';
import { BodyText, ButtonContainer, CheckoutContainer, PickupDetailsContainer, Title, Total, TotalContainer } from './checkout-confirmation.styles.jsx';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { BUTTON_TYPE_CLASSES } from '../../components/button/button.component';
import PaymentButton from '../../components/payment-button/payment-button.component';
import { Fragment, useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CheckoutDetails from '../../components/checkout-details/checkout-details.component';
import Spinner from '../../components/spinner/spinner.component';
import { selectOrder, selectOrderIsError, selectOrderIsLoading, selectOrderTotal } from '../../store/order/order.selector';
import { EmptyCartSubtitleText, EmptyCartText } from '../checkout/checkout.styles';
import NotFoundImage from '../../assets/not-found.png';
import Button from '@mui/material/Button';
import { primaryColor } from '../../constants';
import moment from 'moment';
import { selectOrderType, selectScheduledTime, selectTableNumber } from '../../store/cart/cart.selector';
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { useMediaQuery } from '../../utils/utils.common';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';

const CheckoutConfirmation = () => {
    const location = useLocation();
    const { order } = location.state || {};

    // const [queryParameters] = useSearchParams()
    // const paymentIntentId = queryParameters.get("payment_intent")
    // const isLoading = useSelector(selectOrderIsLoading);
    const isError = useSelector(selectOrderIsError);

    const { kitchenSlug } = useParams();
    const isMobile = useMediaQuery('(max-width: 900px)');

    const getOrderType = order?.orderType ? order.orderType : null;
    const getTableNumber = order?.tableNumber ? order.tableNumber : null;
    const getScheduledTime = order?.scheduledPickupTime !== "ASAP" && order?.scheduledPickupTime !== undefined ? order.scheduledPickupTime : null


    // const order = useSelector(selectOrder);
    // const orderTotal = useSelector(selectOrderTotal);

    const orderTotal = order?.totalAmount ? order.totalAmount : 0;
    const cardTotalFee = order?.onlineCardFeeAmount ? order.onlineCardFeeAmount : 0;

    // const shortPaymentIntent = paymentIntentId?.slice(-8).toUpperCase();
    const shortOrderId = order?.orderId?.slice(-8).toUpperCase() ? order.orderId.slice(-8).toUpperCase() : null

    const userFriendlyId = order?.orderCount ? order.orderCount : shortOrderId;
    const orderWaitTime = order?.orderWaitTime ? order.orderWaitTime : 15;
    const kitchenAddress = order?.kitchenAddress ? order.kitchenAddress : "";
    const customerEmail = order?.customerDetails?.customerEmail ? order.customerDetails.customerEmail : null

    const navigate = useNavigate();

    let time;
    if (order?.createdAt) {

        time = moment(
            order?.createdAt
        ).add(orderWaitTime, "minute").format('hh:mm A');
    }

    // order not found if session id is wrong or not stored in DB
    if (isError instanceof Error) {
        return (
            <CheckoutContainer style={{ textAlign: 'center', justifyContent: 'center', display: 'flex', alignContent: 'center', alignItems: 'center', paddingTop: '22px', paddingBottom: '22px' }}>
                <img src={NotFoundImage} style={{ paddingBottom: '44px' }} alt='page-not-found' />
                <EmptyCartText>Order not found</EmptyCartText>
                <EmptyCartSubtitleText>Click the retry button and we will look for your order again.</EmptyCartSubtitleText>
                <EmptyCartSubtitleText>Reference ID: {shortOrderId}</EmptyCartSubtitleText>

                <Button style={{
                    width: "100%",
                    height: "62px",
                    backgroundColor: primaryColor,
                    color: 'white',
                }} type={BUTTON_TYPE_CLASSES.base} onClick={() => window.location.reload(true)}>Retry</Button>

            </CheckoutContainer>
        )
    }

    // if (isLoading) {
    //     return (
    //         <div style={{
    //             position: 'absolute',
    //             left: window.innerWidth / 2 - 35,
    //             top: window.innerHeight / 2 - 35,
    //         }}>
    //             <Spinner />
    //         </div>
    //     )
    // }

    return (

        <CheckoutContainer>
            {!isMobile && (
                <div style={{ paddingBottom: 16 }}>
                    <Title>Order Sent</Title>
                </div>
            )}
            {/* Pickup details */}
            <PickupDetailsContainer>
                <CheckoutDetails icon={<TaskAltIcon sx={{ color: '#4CD24A' }} />} title={`Order ID: ${userFriendlyId}`} body={`Thank you for your order! A confirmation email will be sent to ${customerEmail} shortly.`} />
                {getOrderType === "Take Away" ? (
                    <CheckoutDetails icon={<ShoppingBagOutlinedIcon sx={{ color: "#475467" }} fontSize='small' />} title={`Pick up ${getScheduledTime ? `at ${getScheduledTime}` : ''}`} body={kitchenAddress} />
                ) : (
                    <CheckoutDetails icon={<TableRestaurantIcon sx={{ color: "#475467" }} fontSize='small' />} title={`Dine In (Table ${getTableNumber})`} body={kitchenAddress} />

                )}
                {getScheduledTime === null && (
                    <CheckoutDetails icon={<ScheduleIcon />} title="Ready in" body={`Approx ${orderWaitTime} minutes (${time?.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })})`} />
                )}
            </PickupDetailsContainer>


            {order?.cartItems?.map((orderItem) => (
                <CheckoutItem key={orderItem.cartItemId} cartItem={orderItem} />
            ))}

            {order?.orderNote !== null && order?.orderNote !== '' && (

                <div style={{
                    margin: 16,
                    marginTop: 24
                }}>

                    <div style={{
                        // alignItems: 'center',
                        display: 'flex',

                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>

                        <div style={{ display: 'flex', gap: 8, }}>

                            <EventNoteOutlinedIcon sx={{ color: "#353435", marginTop: '3px' }} fontSize='small' />

                            <div>

                                <span style={{
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#353435"
                                }}>
                                    Order Note
                                </span>
                                <br />
                                <span style={{
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    color: "#353435"
                                }}>
                                    {order?.orderNote}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            )}
            <ButtonContainer>
                <TotalContainer>

                    <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                        <BodyText>Included GST Amount</BodyText>
                        <Total>${(orderTotal / 11).toFixed(2)}</Total>
                    </div>
                    {cardTotalFee !== 0 && (

                        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
                            <BodyText>Card fee</BodyText>
                            <Total>${Number(cardTotalFee)?.toFixed(2)}</Total>
                        </div>
                    )}
                    {/* <Divider style={{ paddingTop: '10px', marginBottom: '10px' }} /> */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>

                        <Total>Total</Total>
                        <Total>${orderTotal?.toFixed(2)}</Total>
                    </div>
                </TotalContainer>

                <PaymentButton buttonType={BUTTON_TYPE_CLASSES.cart} onClick={() => navigate(`/${kitchenSlug}`)}
                    style={{ flex: 1, marginBottom: 24 }}
                    text="Back to menu" />


            </ButtonContainer>
        </CheckoutContainer>

    )
}

export default CheckoutConfirmation;