import { httpsCallable } from "firebase/functions";
import { db, functions } from "../firebase/firebase.utils";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";

// export const getKitchen = async (kitchenSlug) => {

//   const callTestFunction = httpsCallable(functions, "addMessage");

//  return callTestFunction({ kitchenSlug: kitchenSlug })
//     .then((result) => {
//       // Read result of the Cloud Function.
//       /** @type {any} */
//       const res = result.data;
//       const kitchen = res?.kitchen ? res.kitchen : null;
//       const onlineConfigs = res?.onlineConfigs ? res.onlineConfigs : null;

//     //   const sanitizedMessage = data.text;
//       // console.log(res.kitchen);
//       return {kitchen, onlineConfigs};
//     })
//     .catch((error) => {
//       // Getting the Error details.
//       // const code = error.code;
//       // const message = error.message;
//       // const details = error.details;
//       // ...
//       console.log(error);
//       return error
//     });
// };
export const getKitchenDB = async (kitchenSlug: string) => {
  try {
    // remove the / infront of the path
    const kitchenSlugName = kitchenSlug?.split("/")[1];

    const kichenQuery = query(
      collection(db, "kitchens"),
      where("kitchenSlug", "==", kitchenSlugName),
      limit(1)
    );

    const querySnapshot = await getDocs(kichenQuery);

    // retry request if sessionID is not found. Function cold start needs this
    if (querySnapshot.empty) {
      // console.log("Kitchen not found");
      return { empty: true };
    }

    const kitchenObject = [];

    querySnapshot.forEach((res) => {
      res["kitchenId"] = res.id
      kitchenObject.push(res.data());
    });

    return { kitchen: kitchenObject[0], empty: false };
  } catch (err) {
    throw err;
  }
};

export const getConfigDB = async (kitchenId: string) => {
  try {
    if (kitchenId === undefined || kitchenId === null) {
      return {};
    }

    const configDocRef = doc(db, "onlineOrdersConfigs", kitchenId);
    const configSnapshot = await getDoc(configDocRef);
    let onlineConfigs = null;

    if (!configSnapshot.exists()) {
      onlineConfigs = null;
    } else {
      onlineConfigs = configSnapshot?.data()
        ? configSnapshot.data()
        : null;
    }
    return {onlineConfigs};
  } catch (err) {
    throw err;
  }
};
