import { AnyAction } from "redux";
import {
  fetchKitchenFailed,
  fetchKitchenStart,
  fetchKitchenSuccess,
  setKitchenOpenStatus,
  setOnlineOrdersConfigs,
} from "./kitchen.action";
import { Kitchen, OnlineOrdersConfigs } from "./kitchen.types";

export type KitchenState = {
  readonly kitchen: Kitchen;
  readonly kitchenSlug: string;
  readonly isLoading: boolean;
  readonly error: Error | null;
  readonly kitchenOpen: boolean;
  readonly onlineOrdersConfigs: OnlineOrdersConfigs;
};
export const KITCHEN_INITIAL_STATE: KitchenState = {
  kitchen: {} as Kitchen,
  kitchenSlug: "",
  isLoading: false,
  error: null,
  kitchenOpen: false,
  onlineOrdersConfigs: null,
};

export const categoriesReducer = (
  state = KITCHEN_INITIAL_STATE,
  action: AnyAction
): KitchenState => {
  if (fetchKitchenStart.match(action)) {
    return { ...state, isLoading: true };
  }

  if (fetchKitchenSuccess.match(action)) {
    return { ...state, kitchen: action.payload, isLoading: false };
  }

  if (fetchKitchenFailed.match(action)) {
    return { ...state, error: action.payload, isLoading: false };
  }

  if (setKitchenOpenStatus.match(action)) {
    return { ...state, kitchenOpen: action.payload };
  }

  if (setOnlineOrdersConfigs.match(action)) {
    return {
      ...state,
      onlineOrdersConfigs: action.payload,
    };
  }

  return state;
};
