import React, { useState, useEffect } from "react";
import { ChoicesText, ExtraChargeText, OptionalVarientCategoryContainer, RequiredVarientCategoryContainer, Subtitle, Title, VariantHeaderContainer, VariantsContainer, VarientCategoryText } from "./menu-variants.styles";

// import { BsCircleFill } from "react-icons/bs";
import CircleIcon from '@mui/icons-material/Circle';
import { primaryColor } from "../../constants";
import { useSelector } from "react-redux";
import { selectOnlineOrdersConfigs } from "../../store/kitchen/kitchen.selector";
import { isOnlineOrderConfigValid } from "../../utils/utils.common";

function containsNumbers(str) {
  return /\d/.test(str);
}

export const MenuVariants = ({
  setDishPriceVariant,
  dishPriceVariant,
  maxCounter,
  minCounter,
  optionCategory,
  optionNumber,
  options,
  setVariantSelected,
  variantSelected,
  mandatoryChoice,
  setMinimumVariantsSelected,
  kitchenTier
}) => {
  const [boxesChecked, setBoxesChecked] = useState(-1);
  const [isMaxSelected, setIsMaxSelected] = useState(false);
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  function optionSelected(selection, i) {
    // console.log(containsNumbers(selection.name))
    // console.log(i)
    // console.log("OPTION NUMBER: " + optionNumber)
    let selectedCheckbox;
    if (containsNumbers(selection.name)) {

      selectedCheckbox = document.querySelector(
        `#${CSS.escape(selection.name).replace(/\s/g, '')}`
      );
    } else {
      selectedCheckbox = document.querySelector(
        `#${(selection.name).replace(/\s/g, '')}`
      );
    }


    if (selection.extraCharge) {
      if (!selectedCheckbox.checked) {
        setDishPriceVariant((dishPriceVariant -= Number(selection.charge)));
      } else {
        setDishPriceVariant((dishPriceVariant += Number(selection.charge)));
      }
    }

    if (!selectedCheckbox.checked) {
      const newArray = variantSelected.filter(item => item.id !== `${i}-${optionNumber}`)
      setVariantSelected(newArray)

    } else {
      let newArray = []
      // Add ID to the selection object to find it more easily
      selection["id"] = `${i}-${optionNumber}`;
      selection["optionCategory"] = optionCategory;
      newArray.push(selection)

      setVariantSelected(prevState => prevState.concat(newArray))
    }

    setBoxesChecked(
      document.querySelectorAll(`.checkbox-${optionNumber}:checked`).length
    );
  }

  function getCheckboxes(checkboxNumber) {
    var boxes = document.getElementsByClassName(`checkbox-${checkboxNumber}`);
    return [...boxes];
  }
  useEffect(() => {
    if (mandatoryChoice && maxCounter === boxesChecked) {

      setMinimumVariantsSelected(prevState => prevState + boxesChecked)
    }

    if (mandatoryChoice && boxesChecked === 0) {
      setMinimumVariantsSelected(prevState => prevState - 1)
    }

    // if (mandatoryChoice) {
    //   setMinimumVariantsSelected(prevState => prevState = boxesChecked)
    // }

    if (maxCounter === boxesChecked && maxCounter !== 0) {
      setIsMaxSelected(true);
      getCheckboxes(optionNumber).map(box =>
        (box).checked
          ? ((box).disabled = false)
          : ((box).disabled = true)
      );
    } else {
      setIsMaxSelected(false);
      getCheckboxes(optionNumber).map(
        box => ((box).disabled = false)
      );
    }
    // console.log("boxesChecked = " + boxesChecked)

    return function cleanup() {
      setIsMaxSelected(true);
    };

  }, [boxesChecked, maxCounter, optionNumber]);
  return (
    <VariantsContainer>

      <VariantHeaderContainer>
        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
          <div>
            <Title>{optionCategory}</Title>
          </div>

          {mandatoryChoice && !isMaxSelected && isOnlineOrderConfigValid(onlineOrdersConfig)&& (
            <CircleIcon sx={{ color: primaryColor, height: '8px' }} />

          )}
        </div>
        {(onlineOrdersConfig !== null  && onlineOrdersConfig?.isTyroLocationIdValid) && (

          <div className="category__tag">
            {mandatoryChoice ? (
              <RequiredVarientCategoryContainer>
                <VarientCategoryText>Required</VarientCategoryText>
              </RequiredVarientCategoryContainer>
            ) : (
              <OptionalVarientCategoryContainer>
                <VarientCategoryText>Optional</VarientCategoryText>
              </OptionalVarientCategoryContainer>
            )}
          </div>
        )}
      </VariantHeaderContainer>

      <Subtitle>
        {minCounter === maxCounter
          ? `Choose ${minCounter}`
          : minCounter !== 0 && maxCounter !== 0
            ? `Choose at least ${minCounter} and up to ${maxCounter}`
            : minCounter !== 0 && maxCounter === 0
              ? `Choose at least ${minCounter}`
              : `Choose up to ${maxCounter}`}
      </Subtitle>
      <div style={{ paddingTop: 10 }}>

        {options?.map((selections, i) => {
          return (

            (onlineOrdersConfig !== null  && onlineOrdersConfig?.isTyroLocationIdValid) ? (

              <fieldset key={i} style={{ borderWidth: '0px', alignContent: 'center', display: 'flex', paddingLeft: '0px', paddingBottom: '10px', margin: '0px' }}>
                {isOnlineOrderConfigValid(onlineOrdersConfig) ? (

                  <input
                  id={(selections?.name).replace(/\s/g, '')}
                  type="checkbox"
                  name="action"
                  value="track"
                  className={`checkbox-${optionNumber}`}
                  onChange={() => optionSelected(selections, i)}
                  size='0px'
                  style={{ cursor: 'pointer' }}
                  />
                ): (
                  <div>
                    -
                    </div>
                )}
                <div id={(selections?.name).replace(/\s/g, '')} className={`checkbox-${optionNumber}`}>

                  <ChoicesText >
                    {selections?.name}{" "}
                    {selections.extraCharge && (
                      <ExtraChargeText className="extra-charge">+${Number(selections.charge).toFixed(2)}</ExtraChargeText>
                    )}
                  </ChoicesText>
                </div>
              </fieldset>
            ) : (
              <ul style={{ paddingInlineStart: 16 }}>
                <li> <ChoicesText>
                  {selections?.name}{" "}
                  {selections.extraCharge && (
                    <ExtraChargeText className="extra-charge">+${Number(selections.charge).toFixed(2)}</ExtraChargeText>
                  )}
                </ChoicesText></li>
              </ul>
            )
          );
        })}
      </div>
      {/* <hr /> */}
    </VariantsContainer>
  );
};
