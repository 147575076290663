import styled from 'styled-components';


export const ButtonText = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */

text-align: center;
letter-spacing: 0.38px;

@media screen and (max-width: 900px) {
    font-size: 16px;
  }
`