import styled from 'styled-components';

// import { SpinnerContainer } from '../spinner/spinner.styles';
import { primaryColor, primaryColorDark } from '../../constants';

export const BaseButton = styled.button`
min-width: 165px;
width: auto;
height: 50px;
letter-spacing: 0.5px;
line-height: 50px;
padding: 0 35px 0 35px;
font-size: 15px;
background-color: black;
color: white;
/* text-transform: uppercase; */
font-family: 'Open Sans Condensed';
font-weight: bolder;
border: none;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;

&:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}

`

export const GoogleSignInButton = styled(BaseButton)`
background-color: #4285f4;
color: white;

&:hover {
  background-color: #357ae8;
  border: none;
}
`

export const CartButton = styled(BaseButton)`
background-color: ${primaryColor};
color: white;
border-radius: 8px;
padding: 16px 32px 16px 32px;
height: 56px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
/* width: 858px; */

&:hover {
  background-color: ${primaryColorDark};
  color: white;
  border: none;
}
`

export const InvertedButton = styled(BaseButton)`
box-sizing: border-box;
background-color: white;
color: black;
border-radius: 8px;
padding: 16px 32px 16px 32px;
height: 56px;
border: 1px solid #000000;
/* width: 858px; */

/* Auto layout
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start; */


&:hover {
  background-color: purple;
  color: white;
  border: none;
}
`
