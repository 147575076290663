import {  PoweredByContainer, PoweredByText, RestaurantName, RestaurantNameContainer } from "./footer.styles";
import swiftiLogo from '../../assets/swifti-logo.png';
import { useSelector } from "react-redux";
import { selectKitchen } from "../../store/kitchen/kitchen.selector";
import { Divider } from "@mui/material";

const Footer = ({hideName}) => {
    const kitchen = useSelector(selectKitchen);

    return (
        <div style={{textAlign: "center", }}>
            <Divider style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}/>
            <RestaurantNameContainer>
                {!hideName && (
                    <RestaurantName>{kitchen?.kitchenName}</RestaurantName>
                )}
                <PoweredByContainer>
                    <PoweredByText>Powered By</PoweredByText>
                    <div>
                        <img src={swiftiLogo} alt='logo' height="20px" />
                    </div>
                </PoweredByContainer>
            </RestaurantNameContainer>
        </div>
    )
}

export default Footer;
