import {
  ActionWithPayload,
  createAction,
  withMatcher,
} from "../../utils/reducer/reducer.utils";
import { Dishes } from "../kitchen/kitchen.types";
import { CartItem, CART_ACTION_TYPES, CustomerDetails } from "./cart.types";

const addCartItem = (
  cartItems: CartItem[],
  productToAdd: Dishes,
  quantity: number,
  variantSelected: [],
  dishPrice: number,
  dishPriceWithVariants: number
): CartItem[] => {
  let randomId = Math.random().toString(36).substring(7);

  //find if cart items contains product to add
  // const existingCartItem = cartItems.find(
  //   (cartItem) => cartItem.id === productToAdd.id
  // );

  // If found increment quantity
  // if (existingCartItem) {
  // return cartItems.map((cartItem) =>
  //   cartItem.id === productToAdd.id
  //     ? { ...cartItem, quantity: cartItem.quantity + quantity, dishPrice: dishPriceVariant, variantSelected: variantSelected }
  //     : cartItem
  // );
  // }
  // return new array with modified cartitems/new cart item
  return [
    ...cartItems,
    {
      ...productToAdd,
      quantity: quantity,
      dishPrice: dishPrice,
      dishPriceWithVariants: dishPriceWithVariants,
      variantSelected: variantSelected,
      cartItemId: randomId,
    },
  ];
};

// Add 1 extra item to cart from the checkout page
const incrementCartItem = (
  cartItems: CartItem[],
  productToAdd: Dishes
): CartItem[] => {
  //find if cart items contains product to add
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.cartItemId === productToAdd.cartItemId
  );

  // If found increment quantity
  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.cartItemId === productToAdd.cartItemId
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    );
  }
  // return new array with modified cartitems/new cart item
  return [...cartItems, { ...productToAdd, quantity: 1 }];
};

const removeCartItem = (
  cartItems: CartItem[],
  cartItemToRemove: CartItem
): CartItem[] => {
  // find the cart item to remove
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.cartItemId === cartItemToRemove.cartItemId
  );

  // check if qty = 1 ? remove from cart
  if (existingCartItem && existingCartItem.quantity === 1) {
    return cartItems.filter(
      (cartItem) => cartItem.cartItemId !== cartItemToRemove.cartItemId
    );
  }
  // return cart item with reduced qty
  return cartItems.map((cartItem) =>
    cartItem.cartItemId === cartItemToRemove.cartItemId
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

const clearCartItem = (
  cartItems: CartItem[],
  cartItemToClear: CartItem
): CartItem[] => {
  return cartItems.filter(
    (cartItem) => cartItem.cartItemId !== cartItemToClear.cartItemId
  );
};

export type SetCartItems = ActionWithPayload<
  CART_ACTION_TYPES.SET_CART_ITEMS,
  CartItem[]
>;

export type SetOrderType = ActionWithPayload<
  CART_ACTION_TYPES.SET_ORDER_TYPE,
  string
>;

export type SetOrderNote = ActionWithPayload<
  CART_ACTION_TYPES.SET_ORDER_NOTE,
  string
>;

export type SetCustomerDetails = ActionWithPayload<
  CART_ACTION_TYPES.SET_CUSTOMER_DETAILS,
  CustomerDetails
>;


export type SetTableNumber = ActionWithPayload<
  CART_ACTION_TYPES.SET_TABLE_NUMBER,
  string
>;

export type SetScheduledTime = ActionWithPayload<
  CART_ACTION_TYPES.SET_SCHEDULED_TIME,
  string
>;

export type SetCartKitchenIds = ActionWithPayload<
  CART_ACTION_TYPES.SET_CART_KITCHEN_ID,
  string
>;

export const setCartItems = withMatcher(
  (cartItems: CartItem[]): SetCartItems =>
    createAction(CART_ACTION_TYPES.SET_CART_ITEMS, cartItems)
);

export const setOrderType = withMatcher(
  (orderType: string): SetOrderType =>
    createAction(CART_ACTION_TYPES.SET_ORDER_TYPE, orderType)
);

export const setOrderNote = withMatcher(
  (orderNote: string): SetOrderNote =>
    createAction(CART_ACTION_TYPES.SET_ORDER_NOTE, orderNote)
);

export const setCustomerDetails = withMatcher(
  (customerDetails: CustomerDetails): SetCustomerDetails =>
    createAction(CART_ACTION_TYPES.SET_CUSTOMER_DETAILS, customerDetails)
);

export const setTableNumber = withMatcher(
  (tableNumber: string): SetTableNumber =>
    createAction(CART_ACTION_TYPES.SET_TABLE_NUMBER, tableNumber)
);

export const setScheduledTime = withMatcher(
  (scheduledTime: string): SetScheduledTime =>
    createAction(CART_ACTION_TYPES.SET_SCHEDULED_TIME, scheduledTime)
);

export const setCartKitchenId = withMatcher(
  (kitchenId: string): SetCartKitchenIds =>
    createAction(CART_ACTION_TYPES.SET_CART_KITCHEN_ID, kitchenId)
);

// Add the kitchenID to the cart so we can clear it when visiting different restaurants
export const addKitchenIdToCart = (kitchenId: string) => {
  return setCartKitchenId(kitchenId);
};

export const addItemToCart = (
  cartItems: CartItem[],
  productToAdd: Dishes,
  quantity: number,
  variantSelected: [],
  dishPrice: number,
  dishPriceWithVariants: number
) => {
  const newCartItems = addCartItem(
    cartItems,
    productToAdd,
    quantity,
    variantSelected,
    dishPrice,
    dishPriceWithVariants
  );
  return setCartItems(newCartItems);
};

export const incrementItemInCart = (
  cartItems: CartItem[],
  productToAdd: Dishes
) => {
  const newCartItems = incrementCartItem(cartItems, productToAdd);
  return setCartItems(newCartItems);
};

export const removeItemFromCart = (
  cartItems: CartItem[],
  cartItemToRemove: CartItem
) => {
  const newCartItems = removeCartItem(cartItems, cartItemToRemove);
  return setCartItems(newCartItems);
};

export const clearItemFromCart = (
  cartItems: CartItem[],
  cartItemToClear: CartItem
) => {
  const newCartItems = clearCartItem(cartItems, cartItemToClear);
  return setCartItems(newCartItems);
};

export const clearCart = () => {
  const emptyCart = [];
  return setCartItems(emptyCart);
};

