import { combineReducers } from "redux";
import { cartReducer } from "./cart/cart.reducer";
import { categoriesReducer } from "./kitchen/kitchen.reducer";
import { orderReducer } from "./order/order.reducer";
import { userReducer } from "./user/user.reducer";

export const rootReducer = combineReducers({
    user: userReducer,
    kitchen: categoriesReducer,
    cart: cartReducer,
    order: orderReducer
});