import moment from "moment";

export const businessOpenCheck = (schedule) => {
    const date = moment(); // Today
    const dow = date.isoWeekday(moment().day()).format('dddd'); // Name of the day today
    let isOpen = false;

    // eslint-disable-next-line array-callback-return
    schedule.map((obj) => {
        // FIND TODAYS DAY IN THE BUSINESS' SCHEDULE
        if (dow === Object.keys(obj)[0]) {
            // console.log("FOUND DAY")
            if (moment(Object.values(obj)[0].from, 'hh:mmA').isBefore(moment()) && moment(Object.values(obj)[0].to, 'hh:mmA').isAfter(moment())) {
                isOpen = true
                return isOpen
            }
        }
    })
    return isOpen
}

export const getClosingTimeIncrements = (schedule, waitTime) => {
    const date = moment(); // Today
    const dow = date.isoWeekday(moment().day()).format('dddd'); // Name of the day today
    let closingTime = null;
    let increments = [];
    const now = moment().add(waitTime, 'minutes');

    // eslint-disable-next-line array-callback-return
    schedule.map((obj) => {
        // FIND TODAYS DAY IN THE BUSINESS' SCHEDULE
        if (dow === Object.keys(obj)[0]) {
            // console.log("FOUND DAY")
            closingTime = moment(Object.values(obj)[0].to, 'hh:mmA')
        }
    })

    // Calculate the difference in minutes between now and closing time
    const diffInMinutes = moment(closingTime).diff(now, 'minutes');
    // Round down to the nearest 15-minute interval
    const roundedDiff = Math.ceil(diffInMinutes / waitTime) * waitTime;
    // Generate time increments starting from now, in 15-minute intervals
    for (let i = 0; i <= roundedDiff; i += waitTime) {
        const time = now.clone().add(i, 'minutes');
        // console.log(time)
        // console.log(time.isBefore(closingTime))
        if (time.isBefore(closingTime)) {
            const minute = time.minute();
            const roundedMinute = Math.ceil(minute / 5) * 5;
            time.minute(roundedMinute);
            increments.push(time.format('hh:mmA'));
        }
    }

    return increments
}