import { useDispatch, useSelector } from 'react-redux';
import { incrementItemInCart, removeItemFromCart } from '../../store/cart/cart.action';
import { selectCartItems } from '../../store/cart/cart.selector';
import './checkout-item.styles.jsx';
import { CheckoutItemContainer, HeaderText, PriceText, Quantity, QuantityConfirmation, QuantityConfirmationContainer, Value, VariantText } from './checkout-item.styles.jsx';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useLocation, useParams } from 'react-router-dom';
import { baseDark500 } from '../../constants';
import { Box } from '@mui/material';
const CheckoutItem = ({ cartItem }) => {
    const { pathname } = useLocation();
    const { dishName, dishPriceWithVariants, quantity } = cartItem;

    const cartItems = useSelector(selectCartItems);
    const dispatch = useDispatch();

    // const clearItemHandler = () => dispatch(clearItemFromCart(cartItems, cartItem));
    const addItemHandler = () => dispatch(incrementItemInCart(cartItems, cartItem));
    const removeItemHandler = () => dispatch(removeItemFromCart(cartItems, cartItem));

    const { kitchenSlug } = useParams();

    // console.log(cartItem)
    return (
        <CheckoutItemContainer>

            <div>
                <div >
                    {(pathname === `/${kitchenSlug}/checkout/success` || pathname === `/${kitchenSlug}/checkout/payment`) ? (
                        <Box sx={{ marginTop: '5px'}}>
                            <div style={{ flexDirection: 'row', display: 'flex', gap: '10px' }}>

                                <QuantityConfirmationContainer>
                                    <QuantityConfirmation>{quantity} x</QuantityConfirmation>
                                </QuantityConfirmationContainer>
                         
                                <HeaderText>{dishName}</HeaderText>
                            </div>
                            <div>
                                {cartItem.variantSelected?.length !== 0 && (
                                    cartItem.variantSelected?.map(variant => (
                                        <div>
                                            {/* <VariantText>{variant.optionCategory}: </VariantText> */}
                                            <VariantText>{variant.name} {variant.extraCharge ? `+$${Number(variant.charge).toFixed(2)}` : ''}</VariantText>
                                        </div>
                                    ))
                                )}
                            </div>
                        </Box>
                    ) : (
                        <>
                            <HeaderText>{dishName}</HeaderText>
                            {cartItem.variantSelected?.length !== 0 && (
                                cartItem.variantSelected?.map(variant => (
                                    <div>
                                        {/* <VariantText>{variant.optionCategory}: </VariantText> */}
                                        <VariantText>{variant.name} {variant.extraCharge ? `+$${Number(variant.charge).toFixed(2)}` : ''}</VariantText>
                                    </div>
                                ))
                            )}
                        </>
                    )}
                </div>
                <Quantity>
                    {pathname === `/${kitchenSlug}/checkout` && (
                        <>
                            <RemoveCircleIcon onClick={removeItemHandler} sx={{ color: quantity ===1 ? "lightgrey" : baseDark500, cursor: 'pointer' }} />
                            <Value>{quantity}</Value>
                            <AddCircleIcon onClick={addItemHandler} sx={{ color: baseDark500, cursor: 'pointer' }} />
                        </>
                    )}
                </Quantity>
            </div>
            <div>
                <div className='price'>
                    <PriceText>${(dishPriceWithVariants * quantity).toFixed(2)}</PriceText>
                </div>
            </div>

        </CheckoutItemContainer>
    )
}

export default CheckoutItem;