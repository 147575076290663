import styled from 'styled-components';
export const CheckoutDetailsContainer = styled.div`
    flex-direction: row;
    display: flex;
`
export const Title = styled.span`
/* Text M/Medium */

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
/* or 22px */

letter-spacing: 0.01em;

color: #353435;

@media screen and (max-width: 900px) {
    font-size: 14px;
  }

`

export const Total = styled(Title)`
font-weight: 500;

`;

export const BodyText = styled.span`
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 140%;
/* or 22px */

letter-spacing: 0.01em;

/* Base/Dark */

color: #353435;

@media screen and (max-width: 900px) {
    font-size: 12px;
  }
`