import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CancelIcon from "@mui/icons-material/Cancel";
import { primaryColor, primaryColorDark } from "../../constants";
import Draggable from "react-draggable";
import { Box, Drawer } from "@mui/material";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import FormInput from "../form-input/form-input.component";
import { useDispatch, useSelector } from "react-redux";
import {
  setOrderType,
  setScheduledTime,
  setTableNumber,
} from "../../store/cart/cart.action";
import {
  selectKitchen,
  selectOnlineOrdersConfigs,
} from "../../store/kitchen/kitchen.selector";
import { getClosingTimeIncrements } from "../../utils/closedKitchenCheck";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "../../utils/utils.common";
import { useParams } from "react-router-dom";
import { selectOrderType, selectScheduledTime, selectTableNumber } from "../../store/cart/cart.selector";

interface ContainerProps {
  active: boolean;
}

const ActiveConainer = styled.div<ContainerProps>`
  text-align: center;

  min-width: 94px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 65px;
  box-shadow: ${(props) =>
    props.active ? "0px 2px 4px rgba(16, 24, 40, 0.1)" : "none"};
  background-color: ${(props) => (props.active ? "white" : "none")};

  color: ${(props) => (props.active ? "#344054" : "#667085")};
  cursor: pointer;
`;

const InactiveConainer = styled.div<ContainerProps>`
  text-align: center;
  min-width: 94px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 65px;
  box-shadow: ${(props) =>
    props.active ? "0px 2px 4px rgba(16, 24, 40, 0.1)" : "none"};

  background-color: ${(props) => (props.active ? "white" : "none")};

  color: ${(props) => (props.active ? "#344054" : "#667085")};
  cursor: pointer;
`;

const RadioInput = styled.input`
  width: 18px;
  height: 18px;
  outline: none;
  margin-right: 5px;
  vertical-align: middle;

  &:checked {
    accent-color: #8600ff;
  }
`;

export const ButtonText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.38px;

  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

const OrderTypeBottomSheet = ({ isOpen, toggleBottomSheet, onlineOrdersConfig }) => {
  const getScheduledTime = useSelector(selectScheduledTime);
  // console.log(getScheduledTime)

  const [selectedOption, setSelectedOption] = useState(getScheduledTime === "ASAP" ? "ASAP" : "schedule");
  const getOrderType = useSelector(selectOrderType);

  const [currentOrderType, setCurrentOrderType] = useState(getOrderType);
const getTableNumber = useSelector(selectTableNumber);

  
  const [currentTableNumber, setCurrentTableNumber] = useState(getTableNumber ? getTableNumber : null);
  const [currentScheduledTime, setCurrentScheduledTime] = useState(getScheduledTime);
  

  const kitchen = useSelector(selectKitchen);

  const kitchenSuburb = kitchen?.fullAddress
    ? kitchen?.fullAddress.split(",")[1]?.trim()
    : null;

  const orderWaitTime = onlineOrdersConfig?.orderReadyTime
    ? Number(onlineOrdersConfig?.orderReadyTime)
    : 15;
    
  const getTimeIncrements = getClosingTimeIncrements(
    kitchen.hours.schedule,
    orderWaitTime
  );

  // console.log(getTimeIncrements);
  const dispatch = useDispatch();
  let isMobile = useMediaQuery("(max-width: 900px)");

  const handleOptionChange = (event) => {
    if (event.target.value === "ASAP") {
      setCurrentScheduledTime("ASAP");
    } else {
      setCurrentScheduledTime(null);
    }
    setSelectedOption(event.target.value);
  };

  const handleStop = (event, data) => {
    // If the bottom sheet is dragged above a certain threshold, close it
    if (data.y > 50) {
      toggleBottomSheet();
    }
  };

  const handleTableNumberChange = (event) => {
    const { name, value } = event.target;

    setCurrentTableNumber(value);
  };

  const checkIsDisabled = () => {
    if (currentOrderType === "dinein") {
      if (currentTableNumber === null || currentTableNumber === "") {
        return true;
      }
      return false;
    }

    if (currentOrderType === "takeaway") {
      if (currentScheduledTime === null) {
        return true;
      }
      return false;
    }
  };

  const handleSubmit = () => {
    if (currentOrderType === "dinein") {
      // @ts-ignore
      dispatch(setOrderType("dinein"));
      // @ts-ignore
      dispatch(setTableNumber(currentTableNumber));
    }

    if (currentOrderType === "takeaway") {
      // @ts-ignore
      dispatch(setOrderType("takeaway"));
      // @ts-ignore
      dispatch(setScheduledTime(currentScheduledTime));
    }

    toggleBottomSheet();
  };

  return (
    // <Draggable
    //   axis="y"
    //   cancel=".item"
    //   onStop={handleStop}
    //   bounds={{ top: 0 }} // Restrict to dragging downwards
    // >
    <Drawer
      transitionDuration={{ enter: 500, exit: 500 }}
      open={isOpen}
      anchor="bottom"
      onClose={toggleBottomSheet}
      PaperProps={{
        style: {
          minHeight: isMobile ? "auto" : 350,
          width: isMobile ? "auto" : 400,
          overflow: "hidden", // Ensures no scroll on the drawer itself
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
          padding: 13,
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.25)",
          paddingBottom: 100,
          zIndex: 9999,
          // position: isMobile? "relative": "fixed",
          top: isMobile ? "none" : "50%",
          left: isMobile ? "none" : "50%",
          transform: isMobile ? "none" : "translate(-50%, -50%)",
        },
      }}
    >
      {/* Content of the bottom sheet */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "#344054",
            fontWeight: 500,
            fontSize: 16,
            fontFamily: "Poppins",
          }}
        >
          Order Type
        </p>
        <Box onClick={toggleBottomSheet} className="item">
          <CancelIcon
            style={{
              color: "#3F3F3F",
              width: "36px",
              height: "36px",
              cursor: "pointer",
            }}
          />
        </Box>
      </div>

      {onlineOrdersConfig?.takeAwayEnabled &&
      onlineOrdersConfig?.dineInEnabled ? (
        <div>
          <Box
            style={{
              borderWidth: 1,
              borderColor: "#F2F4F7",
              borderStyle: "solid",
              backgroundColor: "#F9FAFB",
              display: "inline-block",
              flexDirection: "row",
              borderRadius: 100,
              gap: 8,
              padding: 4,
            }}
          >
            <div style={{ display: "flex" }}>
              <ActiveConainer
                className="item"
                active={currentOrderType === "takeaway"}
                onClick={() => setCurrentOrderType("takeaway")}
              >
                Take Away
              </ActiveConainer>

              <InactiveConainer
                className="item"
                active={currentOrderType === "dinein"}
                onClick={() => setCurrentOrderType("dinein")}
              >
                Dine In
              </InactiveConainer>
            </div>
          </Box>
        </div>
      ) : (
        <div>
          {onlineOrdersConfig?.takeAwayEnabled && "Take Away Only"}
          {onlineOrdersConfig?.dineInEnabled && "Dine In Only"}
        </div>
      )}

      {currentOrderType === "takeaway" && (
        <div
          style={{ marginTop: 20, overflowY: "auto", justifyContent: "center" }}
        >
          <span
            style={{
              color: "#344054",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Pick up from {kitchen?.kitchenName}
          </span>
          <br />
          <span
            style={{
              color: "#344054",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            {kitchen?.fullAddress}
          </span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              className="item"
              style={{
                color: "#344054",
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: 14,
                marginBottom: 16,
                marginTop: 16,
              }}
            >
              <RadioInput
                type="radio"
                name="asap"
                value="ASAP"
                checked={selectedOption === "ASAP"}
                onChange={handleOptionChange}
              />
              <span>ASAP (Ready in {orderWaitTime} minutes)</span>
            </label>

            <label
              className="item"
              style={{
                color: "#344054",
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              <RadioInput
                type="radio"
                name="schedule"
                value="schedule"
                checked={selectedOption === "schedule"}
                onChange={handleOptionChange}
              />
              Schedule For Later
            </label>
          </div>

          {selectedOption === "schedule" && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center", // Center items horizontally
                marginTop: 20,
                paddingTop: 20,
                borderTop: "1px solid #EAECF0",
                width: "100%",
                gap: "8px", // Add gap between items
              }}
            >
              {getTimeIncrements.map((increment, index) => {
                return (
                  <div
                    onClick={() => setCurrentScheduledTime(increment)}
                    className="item"
                    style={{
                      background:
                        currentScheduledTime === increment
                          ? "#F9F5FF"
                          : "white",
                      marginBottom: 8,
                      padding: "12px",
                      border:
                        currentScheduledTime === increment
                          ? "2px solid #6C01CC"
                          : "1px solid #EAECF0",
                      borderRadius: 4,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "calc(50% - 4px)", // 50% width minus half the gap
                      textAlign: "center",
                      color:
                        currentScheduledTime === increment
                          ? "#53389E"
                          : "#344054",
                      fontWeight:
                        currentScheduledTime === increment ? 600 : 400,
                    }}
                  >
                    {increment}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {currentOrderType === "dinein" && (
        <div style={{ marginTop: 20 }}>
          <span
            style={{
              color: "#344054",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Dine In at {kitchen?.kitchenName}
          </span>
          <br />
          <span
            style={{
              color: "#344054",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            {kitchen?.fullAddress}
          </span>
          <FormInput
            className="item"
            label="Enter Table Number"
            type="string"
            required
            onChange={handleTableNumberChange}
            name="tableNumber"
            value={currentTableNumber}
            // style={{marginTop: 10}}
          />
        </div>
      )}
      <Button
        className="item"
        style={{
          height: "43px",
          backgroundColor: checkIsDisabled() ? "#808080" : primaryColor,
          color: "white",
          textTransform: "none",
          borderRadius: 8,
          position: "absolute",
          bottom: 20,
          left: 16,
          right: 16,
        }}
        disabled={checkIsDisabled()}
        onClick={handleSubmit}
      >
        <ButtonText>Confirm</ButtonText>
      </Button>
    </Drawer>
    // </Draggable>
  );
};

export default OrderTypeBottomSheet;
