import React, { useState } from "react";
import styled from "styled-components";
import CancelIcon from "@mui/icons-material/Cancel";
import { primaryColor } from "../../constants";
import { Box, Drawer } from "@mui/material";
import Button from "../button/button.component";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerDetails, setOrderNote } from "../../store/cart/cart.action";
import { useMediaQuery } from "../../utils/utils.common";
import {
  selectCustomerDetails,
  selectOrderNote,
} from "../../store/cart/cart.selector";
import FormInput from "../form-input/form-input.component";

export const ButtonText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.38px;

  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`;

const TitleText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */

  letter-spacing: 0.172308px;

  color: #344054;

  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`;

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const OrderCustomerDetailsBottomSheet = ({ isOpen, toggleBottomSheet }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 900px)");

  const customerDetails = useSelector(selectCustomerDetails);
  const defaultFormFields = customerDetails
    ? customerDetails
    : {
        customerEmail: "",
        customerName: "",
        customerMobile: "",
      };
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { customerName, customerEmail, customerMobile } = formFields;

  const checkIsDisabled = () => {
    if (
      customerName !== null &&
      customerName !== "" &&
      customerName.length > 2 &&
      customerEmail !== null &&
      customerEmail !== "" &&
      customerEmail.length > 6 && 
      emailRegex.test(customerEmail.toLowerCase())
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    dispatch(
      setCustomerDetails({
        customerEmail,
        customerName,
        customerMobile,
      })
    );

    toggleBottomSheet();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <Drawer
      transitionDuration={{ appear: 1000, enter: 500, exit: 500 }}
      SlideProps={{ in: true, easing: "sharp" }}
      open={isOpen}
      anchor="bottom"
      onClose={toggleBottomSheet}
      PaperProps={{
        style: {
          minHeight: isMobile ? "auto" : 350,
          width: isMobile ? "auto" : 400,
          overflow: "hidden", // Ensures no scroll on the drawer itself
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 20,
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.25)",
          paddingBottom: 100,
          zIndex: 9999,
          // position: isMobile? "relative": "fixed",
          // top: isMobile ? "none" : "50%",
          top: "50%",
          bottom: isMobile ? "none" : "auto",
          left: isMobile ? "none" : "50%",
          transform: isMobile ? "none" : "translate(-50%, -50%)",
        },
      }}
    >
      <Box role="presentation">
        {/* Content of the bottom sheet */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitleText>Your Details</TitleText>
          <Box onClick={toggleBottomSheet} className="item">
            <CancelIcon
              style={{
                color: "#3F3F3F",
                width: "36px",
                height: "36px",
                cursor: "pointer",
              }}
            />
          </Box>
        </div>

        <FormInput
          label="Name"
          type="string"
          required
          onChange={handleChange}
          name="customerName"
          value={customerName}
          aria-label="Please enter a name"
          aria-required={true}
          // style={{marginTop: 10}}
        />
        <FormInput
          label="Email"
          type="email"
          required
          onChange={handleChange}
          name="customerEmail"
          value={customerEmail}
          // style={{marginTop: 10}}
        />
        <FormInput
          label="Mobile (Optional)"
          type="number"
          onChange={handleChange}
          name="customerMobile"
          value={customerMobile}
          // style={{marginTop: 10}}
        />

        <Button
          className="item"
          style={{
            height: "43px",
            backgroundColor: checkIsDisabled() ? "#808080" : primaryColor,
            color: "white",
            textTransform: "none",
            borderRadius: 8,
            position: "absolute",
            bottom: 20,
            left: 16,
            right: 16,
          }}
          disabled={checkIsDisabled()}
          onClick={handleSubmit}
        >
          <ButtonText>Confirm</ButtonText>
        </Button>
      </Box>
    </Drawer>
  );
};

export default OrderCustomerDetailsBottomSheet;
