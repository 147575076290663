import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCartCount } from '../../store/cart/cart.selector';
import Button, { BUTTON_TYPE_CLASSES } from '../button/button.component';

import { ButtonText, CartIconConatiner } from './cart-button.styles.jsx';


const CartButton = ({style, kitchenSlug, text, onClick, containerStyle}) => {    
    const navigate = useNavigate();

    const cartCount = useSelector(selectCartCount);
    
    const goToCheckoutHandler = () => {
        navigate(`/${kitchenSlug}/checkout`)
    }
    return (
        <CartIconConatiner style={{containerStyle}} onClick={onClick? onClick : goToCheckoutHandler}>
            <Button buttonType={BUTTON_TYPE_CLASSES.cart} style={style}>
                <ButtonText>
                {text ? text : `View Cart (${cartCount})`}
                </ButtonText>
                
                </Button>

        </CartIconConatiner>
    )
}

export default CartButton;