
import { Fragment } from "react";
import { Outlet, Link, useLocation, useParams } from "react-router-dom";

import CloseIcon from '@mui/icons-material/Close';
import ChevronLeft from '@mui/icons-material/ChevronLeft'

// import { signOutUser } from '../../utils/firebase/firebase.utils'

import { NavigationContainer, NavLinks, Title, Subtitle } from './navigation.styles.jsx';
// import { useSelector } from "react-redux";
// import { selectCurrentUser } from "../../store/user/user.selector";
import { primaryColor } from "../../constants";
import SwiftiLogo from "../../assets/swifti-logo.png";

const Navigation = (props) => {
    const { pathname } = useLocation();
    const { kitchenSlug } = useParams();

    return (
        <Fragment>
            <NavigationContainer>
                <NavLinks>
                    {pathname === `/${kitchenSlug}/checkout` && (
                        <>
                            <Link style={{ color: primaryColor }} to={`/${kitchenSlug}`}>
                                <CloseIcon sx={{ width: 40, height: 30 }} />
                            </Link>
                            <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}>
                                <Title>Review Cart</Title>
                                <Subtitle>{props.title}</Subtitle>
                            </div>
                        </>
                    )}

                    {pathname === `/${kitchenSlug}/checkout/payment` && (
                        <>
                            <Link style={{ color: primaryColor }} to={`/${kitchenSlug}/checkout`}>
                                <ChevronLeft sx={{ width: 40, height: 30 }} />
                            </Link>
                            <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}>
                                <Title>Payment details</Title>
                                <Subtitle>{props.title}</Subtitle>
                            </div>
                        </>
                    )}
                    {pathname === `/${kitchenSlug}/checkout/success` && (
                        <>
                            <Link style={{ color: primaryColor }} to={`/${kitchenSlug}`}>
                                <CloseIcon sx={{ width: 40, height: 30 }} />
                            </Link>
                            <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}>

                                <Title>Order Submitted</Title>
                                <Subtitle>{props.title}</Subtitle>

                            </div>
                        </>
                    )}

                    {(pathname === `/` || pathname === `/action`) && (
                        <>
                            <Link style={{ color: primaryColor }} to={`/${kitchenSlug}`}>
                                <img src={SwiftiLogo} alt="logo" height={30}/>
                            </Link>
                        </>
                    )}


                    {/* {currentUser ? (
                        <span className="nav-link" onClick={signOutUser}>SIGN OUT</span>
                        ) : (
                            <NavLink to='/auth'>
                            SIGN IN
                            </NavLink>
                        )} */}
                    {/* <CartIcon /> */}
                </NavLinks>
            </NavigationContainer>
            <Outlet />
        </Fragment>
    )
}

export default Navigation;