import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
body {
  font-family: 'Poppins';
  /* padding: 20px 40px; */
  padding-top: 72px;
  /* max-width: 858px;
  flex: 1; */
  align-items: center;

  @media screen and (max-width: 900px){
    padding-top:10px;
    padding-bottom:10px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
  margin-right: 0px;

  }
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}
`;
