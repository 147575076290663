import styled from 'styled-components';

export const CheckoutItemContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 100px;
  font-size: 20px;
  /* align-items: center; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
align-items: flex-start;
padding: 16px;


background: #FFFFFF;
border-bottom: 0.5px solid rgba(103, 103, 103, 0.25);

`;

export const BaseDiv = styled.div`
  width: 23%;
`;

export const Quantity = styled(BaseDiv)`
  display: flex;
  padding-top: 16px;
  align-items: center;
`;

export const Arrow = styled.div`
  cursor: pointer;
`;

export const Value = styled.span`
  margin: 0 10px;
  font-size: 16px;
`;

export const RemoveButton = styled.div`
  padding-left: 12px;
  cursor: pointer;
`;

export const HeaderText = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
/* or 22px */

letter-spacing: 0.01em;

color: #353435;


@media screen and (max-width: 900px) {
    font-size: 16px;
  }
`

export const PriceText = styled(HeaderText)`
/* padding-left: 20px; */
`

export const QuantityConfirmationContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 1px 3px;
/* gap: 10px; */

/* width: 22px; */
/* height: 19px; */
white-space: nowrap;
background: #ECECEC;
border-radius: 4px;
`
export const QuantityConfirmation = styled.span`
  
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 140%;
/* identical to box height, or 17px */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.01em;

color: #000000;


`

export const VariantText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;

  color: #353435;

  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`