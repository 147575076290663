
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuComponent from '../../components/menu/menu.component';
import Spinner from '../../components/spinner/spinner.component';
import { selectKitchenIsLoading, selectDishesMap, selectKitchen, selectKitchenOpen, selectOnlineOrdersConfigs } from '../../store/kitchen/kitchen.selector';
import { HeaderContainer, HeaderTitle, Logo, MenuBody, NoticeContainer, NoticeContainerText, OrderTypeContainer, PoweredByContainer, PoweredByText, RestaurantOrderTime, RestaurantStatus } from './menu.styles';
import { useLocation, useParams } from 'react-router-dom';
import MenuHeaders from '../../components/menu-headers/menu-headers.component';
import Footer from '../../components/footer/footer.component';
import CartButton from '../../components/cart-button/cart-button.component';
import CircleIcon from '@mui/icons-material/Circle';
import { selectCartKitchenId, selectOrderType, selectScheduledTime, selectTableNumber } from '../../store/cart/cart.selector';
import { clearCart, clearOrderTypeConfigs, setOrderNote, setOrderType, setScheduledTime, setTableNumber } from '../../store/cart/cart.action';
import { kitchenClosedAutoSet, kitchenClosedOverride } from '../../constants';
import { CheckoutContainer, EmptyCartSubtitleText, EmptyCartText } from '../checkout/checkout.styles';
import NotFoundImage from '../../assets/not-found.png';
import swiftiLogo from '../../assets/swifti-logo.png';
import { ChildContainer, MenuContainer } from '../../components/menu/menu.styles';
import { SideDrawer } from '../../components/side-drawer/side-drawer';
import { fetchKitchenAsync } from '../../store/kitchen/kitchen.action';
import { Box } from '@mui/material';
import { analytics } from '../../utils/firebase/firebase.utils';
import { logEvent } from 'firebase/analytics';
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import OrderTypeModal from '../../components/order-type-bottom-sheet/order-type-bottom-sheet.component';
import OrderTypeBottomSheet from '../../components/order-type-bottom-sheet/order-type-bottom-sheet.component';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { isOnlineOrderConfigValid } from '../../utils/utils.common';

const Menu = () => {
    const { pathname } = useLocation();
    // Get the kitchen name from the url to pass to other compoenent
    const { kitchenSlug } = useParams();
    const searchParams = new URLSearchParams(window.location.search);

    // Get individual query parameters
    const tableNumber = searchParams.get('tableNumber');

    const categoriesMap = useSelector(selectDishesMap);

    // console.log("categoriesMap: ")
    const kitchen = useSelector(selectKitchen);
    const isKitchenOpen = useSelector(selectKitchenOpen)

    const allCategories = kitchen?.dishCategories ? kitchen.dishCategories : null;

    const getOrderType = useSelector(selectOrderType);
    const getTableNumber = useSelector(selectTableNumber);
    const getScheduledTime = useSelector(selectScheduledTime);
    const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

    const orderType = getOrderType === "takeaway" ? "Take Away" : "Dine In";

    const isLoading = useSelector(selectKitchenIsLoading);

    const [showOrderTypeBottomSheet, setShowOrderTypeBottomSheet] = useState(false)
    // console.log( moment('11:00AM', 'hh:mmA').format('hh:mm A') );

    const cartKitchenId = useSelector(selectCartKitchenId)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchKitchenAsync(pathname));
    }, [dispatch, pathname])

    useEffect(() => {
        logEvent(analytics, "homepage_visited", {
            kitchenName: kitchen?.kitchenName
        });

    }, [kitchen])

    // useEffect(()=>{
    //     dispatch(setTableNumber(tableNumber ? tableNumber : null))

    // },[tableNumber])

    useEffect(() => {
        // Clear Cart if new restaurant is loaded
        if (kitchen?.kitchenId !== cartKitchenId) {
            const orderType = onlineOrdersConfig?.takeAwayEnabled && !tableNumber ? "takeaway" : "dinein";
            const scheduledTime = "ASAP";
            // console.log("========>>>>" + tableNumber)
            dispatch(setOrderType(orderType))
            dispatch(setOrderNote(null))
            dispatch(setTableNumber(tableNumber ? tableNumber : null))
            dispatch(setScheduledTime(scheduledTime))
            dispatch(clearCart())
        }
    }, [dispatch, cartKitchenId, kitchen, onlineOrdersConfig, tableNumber])


    const sortCategories = (allDishes) => {

        // Create a mapping from category name to category number
        const categoryMap2 = new Map();
        allCategories?.forEach(category => {
            categoryMap2.set(category.name, category.categoryNumber);
        });

        // Function to get the category number from the map key
        const getCategoryNumber = (category) => {
            const categoryKey = category;
            return categoryMap2.get(categoryKey) || Infinity;
        };

        // Sort the map keys based on the category number
        const sortedMap = new Map([...categoriesMap.entries()].sort((a, b) => {
            // console.log(getCategoryNumber(a[0]))
            return getCategoryNumber(a[0]) - getCategoryNumber(b[0]);
        }));

        let content = [];
        for (const [title, dishes] of sortedMap) {
            // Remove the numbers in the category if it starts with a number
            const customTitle = title?.match(/^\d/) ? title.substring(3) : title;
            if (allDishes.size <= 1) {
                content.push(<MenuComponent key={customTitle} products={dishes} kitchen={kitchen} />)
            } else {
                content.push(<MenuComponent key={customTitle} title={customTitle} products={dishes} kitchen={kitchen} />)
            }
        }

        return content
    }

    if (isLoading) {
        return (
            <div style={{
                position: 'absolute',
                left: window.innerWidth / 2 - 35,
                top: window.innerHeight / 2 - 35,
            }}>
                <Spinner />
            </div>
        )
    }

    if (kitchen === null && !isLoading) {
        return (
            <CheckoutContainer style={{ textAlign: 'center', justifyContent: 'center', display: 'flex', alignContent: 'center', alignItems: 'center', paddingTop: '22px', paddingBottom: '22px' }}>
                <img src={NotFoundImage} style={{ paddingBottom: '44px' }} alt='page-not-found' />
                <EmptyCartText>Page not found</EmptyCartText>
                <EmptyCartSubtitleText>The page you are looking for doesn’t exist. Check the link and try again.</EmptyCartSubtitleText>
                <Footer />
            </CheckoutContainer>
        )
    }

    return (
        <Box>
            <MenuContainer>
                <ChildContainer>
                    <HeaderContainer>
                        <div style={{
                            display: 'flex',
                            flex: 1,
                            width: ' 100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <HeaderTitle>{kitchen?.kitchenName}</HeaderTitle>
                            <SideDrawer kitchen={kitchen} />

                        </div>
                        <PoweredByContainer>
                            <PoweredByText>Powered By</PoweredByText>
                            <div>
                                <Logo src={swiftiLogo} />
                            </div>
                        </PoweredByContainer>
                        <div style={{ marginTop: 16, marginBottom: 8 }}>

                            {(kitchen?.kitchenOrderStatus === kitchenClosedAutoSet && isOnlineOrderConfigValid(onlineOrdersConfig)) && (

                                isKitchenOpen ? (

                                    <div>
                                        {isOnlineOrderConfigValid(onlineOrdersConfig) && (
                                            <>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CircleIcon sx={{ color: '#8BCA4D', height: '10px', width: 10 }} />
                                                    <RestaurantStatus>Open</RestaurantStatus>
                                                    <RestaurantOrderTime> (Wait time: {onlineOrdersConfig?.orderReadyTime} Mins)</RestaurantOrderTime>
                                                </div>

                                                <OrderTypeContainer onClick={() => setShowOrderTypeBottomSheet(!showOrderTypeBottomSheet)}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {getOrderType === "dinein" ? (
                                                            <>
                                                                <TableRestaurantIcon sx={{ color: "#475467" }} fontSize='small' />
                                                                <span style={{ color: "#353435", fontWeight: 500, fontSize: 14, fontFamily: "Poppins", paddingLeft: 4 }}>
                                                                    {orderType} {getTableNumber !== null ? `(Table ${getTableNumber})` : "(Select Table)"}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ShoppingBagOutlinedIcon sx={{ color: "#475467" }} fontSize='small' />
                                                                <span style={{ color: "#353435", fontWeight: 500, fontSize: 14, fontFamily: "Poppins", paddingLeft: 4 }}>
                                                                    {orderType} {getScheduledTime === "ASAP" ? `${getScheduledTime}` : `at ${getScheduledTime}`}
                                                                </span>
                                                            </>
                                                        )}


                                                        <span style={{ color: "#8600FF", fontWeight: 500, fontSize: 12, fontFamily: "Poppins", paddingLeft: 8 }}>
                                                            Change
                                                        </span>

                                                    </div>
                                                </OrderTypeContainer>
                                            </>
                                        )}

                                    </div>
                                ) : (
                                    <>
                                        <CircleIcon sx={{ color: '#F04438', height: '10px', width: 10 }} />
                                        <RestaurantStatus>Venue is currently closed.</RestaurantStatus>
                                    </>
                                ))}

                            {(kitchen?.kitchenOrderStatus !== kitchenClosedAutoSet && isOnlineOrderConfigValid(onlineOrdersConfig)) && (
                                (kitchen?.kitchenOrderStatus === kitchenClosedOverride) ? (
                                    <>
                                        <CircleIcon sx={{ color: '#F04438', height: '10px', width: 10 }} />
                                        <RestaurantStatus>Venue is currently closed.</RestaurantStatus>
                                    </>
                                ) : (
                                    <div>
                                        {isOnlineOrderConfigValid(onlineOrdersConfig) && (
                                            <>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CircleIcon sx={{ color: '#8BCA4D', height: '10px', width: 10 }} />
                                                    <RestaurantStatus>Open</RestaurantStatus>
                                                    <RestaurantOrderTime> (Wait time: {onlineOrdersConfig?.orderReadyTime} Mins)</RestaurantOrderTime>
                                                </div>

                                                <OrderTypeContainer onClick={() => setShowOrderTypeBottomSheet(!showOrderTypeBottomSheet)}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {getOrderType === "dinein" ? (
                                                            <>
                                                                <TableRestaurantIcon sx={{ color: "#475467" }} fontSize='small' />
                                                                <span style={{ color: "#353435", fontWeight: 500, fontSize: 14, fontFamily: "Poppins", paddingLeft: 4 }}>
                                                                    {orderType} (Table {getTableNumber})
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ShoppingBagOutlinedIcon sx={{ color: "#475467" }} fontSize='small' />
                                                                <span style={{ color: "#353435", fontWeight: 500, fontSize: 14, fontFamily: "Poppins", paddingLeft: 4 }}>
                                                                    {orderType} {getScheduledTime === "ASAP" ? `${getScheduledTime}` : `at ${getScheduledTime}`}
                                                                </span>
                                                            </>
                                                        )}
                                                        <span style={{ color: "#8600FF", fontWeight: 500, fontSize: 12, fontFamily: "Poppins", paddingLeft: 8 }}>
                                                            Change
                                                        </span>
                                                    </div>
                                                </OrderTypeContainer>
                                            </>
                                        )}
                                    </div>
                                )
                            )}

                            {!isOnlineOrderConfigValid(onlineOrdersConfig) && (
                                <RestaurantStatus style={{ paddingLeft: 0 }}>Visual Menu Only</RestaurantStatus>

                            )}
                        </div>
                    </HeaderContainer>

                    <MenuHeaders categoriesMap={categoriesMap} allCategories={allCategories} />
                    <MenuBody>

                        {sortCategories(categoriesMap)}
                    </MenuBody>
                </ChildContainer>
            </MenuContainer>
            <div style={{ paddingBottom: (isOnlineOrderConfigValid(onlineOrdersConfig)) ? '100px' : '10px' }}>

                <Footer />
            </div>
            {isOnlineOrderConfigValid(onlineOrdersConfig) && (

                <div>
                    {kitchen?.kitchenOrderStatus === kitchenClosedAutoSet && (

                        isKitchenOpen ? (

                            <CartButton kitchenSlug={kitchenSlug} style={{ width: '858px' }} />
                        ) : (
                            <NoticeContainer>

                                <NoticeContainerText>Venue is currently closed</NoticeContainerText>
                            </NoticeContainer>
                        ))}


                    {(kitchen?.kitchenOrderStatus !== kitchenClosedAutoSet) && (
                        (kitchen?.kitchenOrderStatus === kitchenClosedOverride) ? (
                            <NoticeContainer>
                                <NoticeContainerText>Venue temporarily not accepting online orders</NoticeContainerText>
                            </NoticeContainer>
                        ) : (
                            <CartButton kitchenSlug={kitchenSlug} style={{ width: '858px' }} />

                        ))}
                </div>


            )}
            {showOrderTypeBottomSheet && (
                <OrderTypeBottomSheet isOpen={showOrderTypeBottomSheet} toggleBottomSheet={() => setShowOrderTypeBottomSheet(false)} onlineOrdersConfig={onlineOrdersConfig} />
            )}

        </Box>
    )
}

export default Menu;