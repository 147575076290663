import React, { useState } from "react";
import styled from "styled-components";
import CancelIcon from "@mui/icons-material/Cancel";
import { primaryColor } from "../../constants";
import { Box, Drawer } from "@mui/material";
import Button from "../button/button.component";
import { useDispatch, useSelector } from "react-redux";
import { setOrderNote } from "../../store/cart/cart.action";
import { useMediaQuery } from "../../utils/utils.common";
import { selectOrderNote } from "../../store/cart/cart.selector";

export const ButtonText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.38px;

  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`;

const OrderNoteBottomSheet = ({ isOpen, toggleBottomSheet }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 900px)");
  const orderNote = useSelector(selectOrderNote);
  const [text, setText] = useState(orderNote);

  const checkIsDisabled = () => {
    if (text !== null && text !== "" && text?.length > 2) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    dispatch(setOrderNote(text));
    toggleBottomSheet();
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <Drawer
      transitionDuration={{ appear: 1000, enter: 500, exit: 500 }}
      SlideProps={{ in: true, easing: "sharp" }}
      open={isOpen}
      anchor="bottom"
      onClose={toggleBottomSheet}
      PaperProps={{
        style: {
          minHeight: isMobile ? "auto" : 350,
          width: isMobile ? "auto" : 400,
          overflow: "hidden", // Ensures no scroll on the drawer itself
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
          padding: 13,
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.25)",
          paddingBottom: 100,
          zIndex: 9999,
          // position: isMobile? "relative": "fixed",
          top: isMobile ? "none" : "50%",
          left: isMobile ? "none" : "50%",
          transform: isMobile ? "none" : "translate(-50%, -50%)",
        },
      }}
    >
      <Box role="presentation">
        {/* Content of the bottom sheet */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "#344054",
              fontWeight: 500,
              fontSize: 16,
              fontFamily: "Poppins",
            }}
          >
            Order Note
          </p>
          <Box onClick={toggleBottomSheet} className="item">
            <CancelIcon
              style={{
                color: "#3F3F3F",
                width: "36px",
                height: "36px",
                cursor: "pointer",
              }}
            />
          </Box>
        </div>
        <div
          style={{
            color: "#344054",
            paddingBottom: 16,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          Add order note below.
        </div>
        <textarea
          value={text}
          onChange={handleChange}
          placeholder="Enter text here..."
          autoFocus={true}
          style={{
            width: "100%",
            height: "76px",
            borderColor: "#DFDFDF",
            borderRadius: 8,
            padding: 8,
            outlineColor: primaryColor,
          }}
        />
        <div
          onClick={() => {
            dispatch(setOrderNote(null));
            toggleBottomSheet();
          }}
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 14,
            color: primaryColor,
            paddingTop: 8,
          }}
        >
          Clear Note
        </div>
        <Button
          className="item"
          style={{
            height: "43px",
            backgroundColor: checkIsDisabled() ? "#808080" : primaryColor,
            color: "white",
            textTransform: "none",
            borderRadius: 8,
            position: "absolute",
            bottom: 20,
            left: 16,
            right: 16,
          }}
          disabled={checkIsDisabled()}
          onClick={handleSubmit}
        >
          <ButtonText>Add Note</ButtonText>
        </Button>
      </Box>
    </Drawer>
  );
};

export default OrderNoteBottomSheet;
