import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  margin-bottom: 30px;
  max-width: 858px;
  margin-left: auto;
  margin-right: auto;
  
  /* flex: 1 */
  /* min-height: 85vh; */
  
  /* @media screen and (max-width: 900px){
    align-items: center;
  } */
`;

export const ChildContainer = styled.div`


  margin-left: auto;
  margin-right: auto;
  width: 70%;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    width: 100%;
    /* padding-left: 10px;
    padding-right: 10px; */
  }

`


export const Title = styled.span`
  margin-bottom: 50px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 34px;
  letter-spacing: 0.38px;

  color: #000000;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  /* scroll-margin-top: 50px; */
  padding-left: 10px;
    padding-right: 10px;
  @media screen and (max-width: 900px) {
    /* padding-left: 16px; */
    font-size: 18px;
  }

`;

export const Preview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 34px;
  row-gap: 34px;
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;
  padding-left: 10px;
    padding-right: 10px;

  @media screen and (max-width: 900px){
  grid-gap: 10px;
  }

  @media screen and (max-width: 600px){
  grid-template-columns: 1fr;
  grid-gap: 15px;
  }

`;