import styled from 'styled-components';
import { baseDark500 } from '../../constants';

const BaseText = styled.span`
font-family: 'Poppins';
font-style: normal;
letter-spacing: 0.172308px;
color: #000000;
`

export const VariantsContainer = styled.div`
margin-left: 20px;
margin-right: 20px;
border-top: 0.5px solid #C7C7C7;
padding-top: 14px;
margin-top: 20px;
`

export const VariantHeaderContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-content: center;
align-items: center;
padding: 0px;
`

export const Title = styled(BaseText)`
font-weight: 500;
font-size: 14px;
line-height: 21px;
`

export const Subtitle = styled(BaseText)`
font-weight: 400;
font-size: 12px;
line-height: 18px;
letter-spacing: 0.341818px;
color: ${baseDark500};
`
export const RequiredVarientCategoryContainer = styled.div`
    background: #FFDFDE;
    border-radius: 3px;
    padding-left: 3px;
    padding-right: 3px;
`

export const OptionalVarientCategoryContainer = styled.div`
    background: #C8E5F8;
    border-radius: 3px;
    padding-left: 3px;
    padding-right: 3px;
`
export const VarientCategoryText = styled(BaseText)`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    
    letter-spacing: 0.341818px;
    
    color: ${baseDark500};

`

export const ChoicesText = styled(BaseText)`
font-weight: 400;
font-size: 14px;
line-height: 21px;
margin-left: 5px;
letter-spacing: 0.118462px;
`

export const ExtraChargeText = styled(BaseText)`
font-weight: 500;
font-size: 12px;
line-height: 18px;
letter-spacing: 0.341818px;

color: ${baseDark500};
`