import styled from "styled-components";

export const CheckoutContainer = styled.div`
  width: 526px;
  height: 100%;
  flex-direction: column;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border: 1.5px solid #f6f6f6;
  filter: drop-shadow(0px 4px 14px rgba(174, 174, 174, 0.25));
  border-radius: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: white;
  margin-top: 70px;
  overflow-y: auto;
  margin-bottom: 200px;

  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 0px;
    margin-top: 55px;

    border: none;
    filter: none;
  }
`;

export const Title = styled.span`
  /* Text M/Medium */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.01em;

  color: #353435;
  /* padding-bottom: 16px; */
`;

export const Total = styled(Title)`
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`;


export const BodyText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.01em;

  /* Base/Dark */

  color: #353435;

  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`;

export const ButtonText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  letter-spacing: 0.01em;
  cursor: pointer;

  color: #8600FF;

  &:hover {
    color: #6C01CC; 
  }

  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

export const PickupDetailsContainer = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  width: 100%;

  /* border-bottom: 1px solid rgba(174, 174, 174, 0.5); */

  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #F9FAFB
`;

export const BottomContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
  cursor: pointer;
  background-color: white;

  @media screen and (max-width: 900px) {
    max-width: 100%;
    margin-left: 0px;
  margin-right: 0px;
    margin-top: 55px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
`;

export const TotalContainer = styled.div`
  /* display: flex;
 justify-content: space-between; */
  padding: 24px;
  border-top: 1px solid #F6F6F6;
  background-color: white;

  @media screen and (max-width: 900px) {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;

    position: fixed;
    bottom: 90px;
    left: 16px;
    right: 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  
  @media screen and (max-width: 900px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;


  }
`;

export const EmptyCartText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 140%;
  letter-spacing: 0.38px;

  color: #000000;
`;

export const EmptyCartSubtitleText = styled(EmptyCartText)`
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.01em;
  padding-top: 23px;
  padding-bottom: 24px;
`;


export const OrderNoteText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  color: #000000;

  @media screen and (max-width: 900px) {
    font-size: 16px;



  }

`;