import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getConfigDB, getKitchenDB } from "../../utils/firestore/getKitchen";
import {
  Action,
  ActionWithPayload,
  createAction,
  withMatcher,
} from "../../utils/reducer/reducer.utils";
import { RootState } from "../store";
import {
  KITCHEN_ACTION_TYPES,
  Kitchen,
  OnlineOrdersConfigs,
} from "./kitchen.types";
import { kitchenClosedAutoSet } from "../../constants";
import { businessOpenCheck } from "../../utils/closedKitchenCheck";

export type FetchKitchenStart =
  Action<KITCHEN_ACTION_TYPES.FETCH_KITCHEN_START>;
export type FetchKitchenSucess = ActionWithPayload<
  KITCHEN_ACTION_TYPES.FETCH_KITCHEN_SUCCESS,
  Kitchen
>;
export type FetchKitchenFailed = ActionWithPayload<
  KITCHEN_ACTION_TYPES.FETCH_KITCHEN_FAILED,
  Error
>;
export type SetKitchenOpenStatus = ActionWithPayload<
  KITCHEN_ACTION_TYPES.SET_KITCHEN_OPEN_STATUS,
  boolean
>;
export type SetOnlineOrdersConfigs = ActionWithPayload<
  KITCHEN_ACTION_TYPES.SET_ONLINE_ORDERS_CONFIGS,
  OnlineOrdersConfigs
>;

export const fetchKitchenStart = withMatcher(
  (): FetchKitchenStart =>
    createAction(KITCHEN_ACTION_TYPES.FETCH_KITCHEN_START)
);

export const fetchKitchenSuccess = withMatcher(
  (kitchen: Kitchen): FetchKitchenSucess =>
    createAction(KITCHEN_ACTION_TYPES.FETCH_KITCHEN_SUCCESS, kitchen)
);

export const setKitchenOpenStatus = withMatcher(
  (open: boolean): SetKitchenOpenStatus =>
    createAction(KITCHEN_ACTION_TYPES.SET_KITCHEN_OPEN_STATUS, open)
);

export const setOnlineOrdersConfigs = withMatcher(
  (configs: OnlineOrdersConfigs): SetOnlineOrdersConfigs =>
    createAction(KITCHEN_ACTION_TYPES.SET_ONLINE_ORDERS_CONFIGS, configs)
);

export const fetchKitchenFailed = withMatcher(
  (error: Error): FetchKitchenFailed =>
    createAction(KITCHEN_ACTION_TYPES.FETCH_KITCHEN_FAILED, error)
);

export const fetchKitchenAsync =
  (kitchenSlug: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(fetchKitchenStart());
    try {
      const { kitchen } = await getKitchenDB(kitchenSlug);

      const {onlineConfigs } = await getConfigDB(kitchen?.kitchenId)

      // console.log(onlineConfigs);
      if (kitchen?.kitchenOrderStatus === kitchenClosedAutoSet) {
        const isOpen = businessOpenCheck(kitchen?.hours?.schedule);
        // console.log("============ " + isOpen);
        dispatch(setKitchenOpenStatus(isOpen));
      }

      dispatch(setOnlineOrdersConfigs(onlineConfigs));

      dispatch(fetchKitchenSuccess(kitchen));
    } catch (error) {
      dispatch(fetchKitchenFailed(error as Error));
    }
  };
