import { BodyText, CheckoutDetailsContainer, Title } from "./checkout-details.stylex"

const CheckoutDetails = ({icon, title, body}) => {
    return (
        <CheckoutDetailsContainer>
            <div style={{marginTop: 3}}>
                {icon}
            </div>
            <div style={{ paddingLeft: '8px' }}>
                <Title>{title}</Title>
                <br />
                <BodyText>{body}</BodyText>
            </div>
        </CheckoutDetailsContainer>
    )
}

export default CheckoutDetails;