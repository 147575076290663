import { primaryColor } from "../../constants";
// import { SpinnerContainer } from "./spinner.styles";
import ClipLoader from "react-spinners/ClipLoader";

const Spinner = ({ size, color }) => (
    // <SpinnerContainer />
    <ClipLoader
        color={color ? color : primaryColor}
        loading={true}
        cssOverride={{
            display: "block",
            margin: "0 auto",
        }}
        size={size ? size : 70}
        aria-label="Loading Spinner"
        data-testid="loader"
    />
);

export default Spinner;