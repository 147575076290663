import styled from 'styled-components';

export const CartIconConatiner = styled.div`

/* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* z-index: 0; */
  /* position: -webkit-sticky;
  position: sticky; */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 20px; */
  /* backdrop-filter: blur(2px); */
  padding: 20px;
  max-width: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(187, 187, 187, 0.76);
`

export const ButtonText = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */

text-align: center;
letter-spacing: 0.38px;


@media screen and (max-width: 900px) {
    font-size: 16px;
  }
`