import { useEffect } from "react";
import RedirectImage from '../../assets/redirect.png';
import { baseDark600 } from "../../constants";
import { Image, MainContainer, SubTitle, Title } from "./redirect.styles";
import { Box } from "@mui/material";

const RedirectPage = () => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            // 👇️ redirects to an external URL
            window.location.replace('https://swifti.com.au');
        }, 3000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <MainContainer>
            <Box>
                <Image src={RedirectImage} alt='page-not-found' />
            </Box>
            <Title>
                Ooops... page not found!
            </Title>
            <SubTitle>
                Redirecting you to our home page now
            </SubTitle>
        </MainContainer>
    )
}

export default RedirectPage;
