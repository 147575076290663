// import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
	align-items: center;
	position: -webkit-sticky;
	position: sticky;
	top: -5px;
	z-index: 2;
	height: 70px;
	min-height: 70px;
	/* width: calc(100% - 1.5rem); */
	background-color: #fff;
	/* margin: 1rem 0rem; */
	/* box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%); */
    /* border-bottom: 1px bold black; */
    padding-left: 10px;
    padding-right: 10px;
`



export const UnorderedList = styled.ul`
    align-items: center;
    display: flex;
    gap:2rem;
    font-size: 0.9rem;
    list-style: none;
    padding: 10px;
    padding-left: 0;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;


    ::-webkit-scrollbar {
        display: none;
    }
    `
