import styled from "styled-components";
import { baseDark600 } from "../../constants";
const TextBase = styled.span`
  font-family: "Poppins";
  font-style: normal;
  letter-spacing: 0.38px;
  color: #ffffff;
`;

export const HeaderContainer = styled.div`
  /* max-height: 466px; */
  width: 100%;
  /* box-shadow: 0px 4px 14px rgba(196, 196, 196, 0.25); */
  /* padding-left: 20px;
padding-right: 20px; */
  /* margin-left: 20px; */
  padding-left: 10px;
  padding-right: 10px;
`;

export const HeaderTitle = styled(TextBase)`
  font-weight: 500;
  font-size: 40px;
  color: ${baseDark600};
  @media screen and (max-width: 900px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
  }
`;

export const PoweredByContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 4px;
  /* @media screen and (max-width: 600px) {
    justify-content: center;
} */
`;
export const PoweredByText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  letter-spacing: 0.38px;

  color: ${baseDark600};

  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const RestaurantLogo = styled.img`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px;
  /* gap: 10px; */

  width: 130px;
  height: 72px;

  object-fit: cover;

  @media screen and (max-width: 900px) {
    width: 69px;
    height: 31px;
  }
`;

export const RestaurantTitle = styled(TextBase)`
  font-weight: 600;
  font-size: 40px;

  @media screen and (max-width: 900px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const RestaurantDetails = styled(TextBase)`
  font-weight: 400;
  font-size: 24px;

  @media screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
`;
export const RestaurantStatus = styled(TextBase)`
  font-weight: 600;
  font-size: 18px;
  color: ${baseDark600};
  padding-left: 5px;
  @media screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const RestaurantOrderTime = styled(TextBase)`
  font-weight: 400;
  font-size: 18px;
  color: ${baseDark600};

  @media screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Logo = styled.img`
  /* position: absolute; */
  height: 20px;
  /* width: 90px; */
  background-image: ${({ src }) => src};
  /* object-fit: cover; */
  @media screen and (max-width: 600px) {
    height: 15px;
    /* width: 70px; */
  }
`;

export const BodyContainer = styled.div`
  flex: 1;
`;

export const NoticeContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1;
  display: flex;
  padding: 20px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(187, 187, 187, 0.76);
`;

export const NoticeContainerText = styled(TextBase)`
  font-weight: 500;
  font-size: 20px;
  color: red;
  text-align: center;
  @media screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const MenuBody = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

export const OrderTypeContainer = styled.button`
align-items: center;
display: inline-block;
flex-direction: row;
padding: 10px;
margin-top: 20px;
background-color: #F5F7F9;
border-radius: 84px;
border: none;
cursor: pointer;
transition: background-color 0.3s ease; /* Transition for smooth effect */
&:hover {
  background-color: #c7d0d9; /* Darker color on hover */
}
`

export const BottomContainer = styled.div`
  cursor: pointer;
  border-top: 1px solid #BBBBBB;
  align-items: center;
  justify-content: center;
  display: flex;
 

  @media screen and (max-width: 900px) {
    max-width: 100%;
    margin-left: 0px;
  margin-right: 0px;
    margin-top: 55px;
    bottom: 24px;
    left: 16px;
    right: 16px;
  }
`;