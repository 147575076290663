
import styled from 'styled-components';
import Box from '@mui/material/Box';


const TextBase = styled.span`
font-family: 'Poppins';
font-style: normal;
letter-spacing: 0.38px;
color: #FFFFFF;
`
export const WarningText = styled(TextBase)`
font-weight: 400;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.118462px;

color: #FF6961;
`


export const NoticeContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
 z-index: 1;
 /* position: -webkit-sticky; */
position: absolute;
bottom: 0;
left: 0;
right: 0;
margin-left: auto;
margin-right: auto;
/* margin-bottom: 20px; */
background: rgba(255, 255, 255, 0.5);
backdrop-filter: blur(2px);
padding: 20px;
max-width: 100%;
/* height: 100%; */
border-top: 1px solid rgba(187, 187, 187, 0.76);
/* flex: 1 */
`

export const NoticeContainerText = styled(TextBase)`
font-weight: 500;
font-size: 20px;
color: red;
text-align: center;
@media screen and (max-width: 900px) {
font-weight: 500;
font-size: 14px;
line-height: 18px;
}
`