import styled, { css } from "styled-components";
import { baseDark500 } from "../../constants";

const shrinkLabelStyles = css`
  top: 0px;
  font-size: 12px;
  color: ${baseDark500};
`;

type FormInputLabelProps = {
  shrink?: boolean;
};

export const FormInputLabel = styled.label<FormInputLabelProps>`
  color: ${baseDark500};
  /* font-size: 16px; */
  /* font-weight: normal; */
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 14px;
  transition: 300ms ease all;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  ${({ shrink }) => shrink && shrinkLabelStyles}
`;

export const Input = styled.input`
  /* background: none; */
  background-color: white;
  color: ${baseDark500};
  font-size: 14px;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 5px;
  display: block;
  width: 100%;
  border-bottom: 1px solid ${baseDark500};
  /* margin: 16px 0; */
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
  background: #ffffff;
  border: 1px solid #DFDFDF;
  /* box-shadow: 0px 1px 2px rgba(170, 170, 170, 0.5); */
  border-radius: 5px;

  &:focus {
    outline: none;
  }

  &:focus ~ ${FormInputLabel} {
    ${shrinkLabelStyles};
  }
`;

export const Group = styled.div`
  position: relative;
  /* margin: 10px 0; */
  width: 100%;
  display: flex;

  input[type="password"] {
    letter-spacing: 0.3em;
  }
`;
